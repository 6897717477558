import React from 'react';
import './Settings.scss';
import { Card } from 'semantic-ui-react';
import SettingsCard from './SettingsCard';
import { motion } from 'framer-motion';
import PermissionCheck from '../../Reusable/PermissionCheck';
import p from '../../../helpers/permissions';

export default function SettingsList() {
  return (
    <div className='settings-list'>
      <Card.Group className='settings-card-group'>
        <PermissionCheck permissions={[p.CREATE_CLIENT, p.VIEW_CLIENTS, p.EDIT_CLIENT, p.DELETE_CLIENT]}>
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard title='العملاء' iconName='users' url='/customers' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck
          permissions={[p.ACTIVATE_NEW_USER, p.CREATE_USER, p.DELETE_USER, p.EDIT_USER, p.CHANGE_USER_PASSWORD]}
        >
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: -100 }}
          >
            <SettingsCard title='المستخدمين' iconName='user circle' url='/users' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck
          permissions={[p.VIEW_VEHICLES, p.EDIT_VEHICLE, p.CREATE_VEHICLE, p.DELETE_VEHICLE, p.VIEW_ALL_VEHICLES]}
        >
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard title='المركبات' iconName='car' url='/vehicles' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck
          permissions={[
            p.CREATE_TRACKING_UNIT,
            p.EDIT_TRACKING_UNIT,
            p.VIEW_TRACKING_UNITS,
            p.DELETE_TRACKING_UNIT,
            p.VIEW_ALL_TRACKING_UNITS,
          ]}
        >
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: -100 }}
          >
            <SettingsCard title='اجهزة التتبع' iconName='map pin' url='/trackingUnits' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck permissions={[p.VIEW_ROLES, p.EDIT_ROLE, p.CREATE_ROLE, p.DELETE_ROLE]}>
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard title='الصلاحيات' iconName='list alternate' url='/roles' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck permissions={[p.VIEW_LOOKUP, p.EDIT_LOOKUP, p.CREATE_LOOKUP, p.DELETE_LOOKUP]}>
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: -100 }}
          >
            <SettingsCard title='رموز النظام' iconName='star' url='/lookupsGroups' />
          </motion.div>
        </PermissionCheck>
      </Card.Group>
    </div>
  );
}
