import React from 'react';
import TicketsSideMenu from './TicketsSideMenu';
import { SysTicketDetailsView } from '../SystemMaintenanceTickets/SysTicketDetailsView';

const CustomerTicketsMobile = ({
  loading,
  fetchData,
  data,
  onTicketSelected,
  handleDeleteTicket,
  onTicketStatusChanged,
  exportFileHandler,
  selectedTicket,
}) => {
  return (
    <div className='customer-tickets-container'>
      {!selectedTicket ? (
        <TicketsSideMenu
          data={data}
          exportFileHandler={exportFileHandler}
          fetchData={fetchData}
          loading={loading}
          onTicketSelected={onTicketSelected}
        />
      ) : (
        <div className='ticket-details'>
          {
            {
              15: (
                <SysTicketDetailsView
                  onDelete={() => handleDeleteTicket()}
                  onStatusChanged={onTicketStatusChanged}
                  key={selectedTicket.id}
                  ticket={selectedTicket}
                  onTicketSelected={onTicketSelected}
                />
              ),
              16: (
                <SysTicketDetailsView
                  onDelete={() => handleDeleteTicket()}
                  onStatusChanged={onTicketStatusChanged}
                  key={selectedTicket.id}
                  ticket={selectedTicket}
                  onTicketSelected={onTicketSelected}
                />
              ),
              26: (
                <SysTicketDetailsView
                  onDelete={() => handleDeleteTicket()}
                  onStatusChanged={onTicketStatusChanged}
                  key={selectedTicket.id}
                  ticket={selectedTicket}
                  onTicketSelected={onTicketSelected}
                />
              ),
            }[selectedTicket.type]
          }
        </div>
      )}
    </div>
  );
};

export default CustomerTicketsMobile;
