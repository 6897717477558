import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Form, Icon, Responsive, Image, Tab, Message } from 'semantic-ui-react';
import { useHistory, useLocation } from 'react-router-dom';
import PageCardContainer from '../../../Layout/PageCardContainer';
import './SysTicketAddForm.scss';
import Webcam from 'react-webcam';
import { useSelector } from 'react-redux';
import { customersApi, customerTicketsApi, lookupsApi } from '../../../../api/api';

const SysTicketAddForm = () => {
  const webcamRef = React.useRef(null);
  const router = useHistory();
  const location = useLocation();
  const userInfo = useSelector((st) => st.user);

  // COMPONENT STATE DECLARATIONS //
  const [customerIdOpts, setCustomerIdOpts] = useState([]);
  const [ticketTypeOpts, setTicketTypeOpts] = useState([]);
  const [subTypeOpts, setSubTypeOpts] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({ key: null, message: null });
  const [editMode, setEditMode] = useState(false);
  const [levelIdOpts, setLevelIdOpts] = useState([]);

  const [formData, setFormData] = useState({
    type: null,
    subType: null,
    image: null,
    customerId: null,
    levelId: null,
    note: '',
    comment: '',
    createdBy: null,
    commentType: 28, // [ADD NOTE]
    status: 18, // [Open]
  });
  const [validationErrors, setValidationErrors] = useState({
    type: null,
    subType: null,
    customerId: null,
    levelId: null,
    note: null,
  });
  const [touched, setTouched] = useState({
    type: false,
    subType: false,
    customerId: false,
    levelId: false,
    note: false,
  });
  // End of COMPONENT STATE DECLARATIONS //

  // EFFECTS //
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsSubmitting(true);
        const customers = await customersApi.getCustomersDropDown();
        setCustomerIdOpts(
          customers.data.map((item) => ({
            key: item.id,
            text: item.name,
            value: item.id,
          }))
        );
        const levels = await lookupsApi.getGroup(72);
        setLevelIdOpts(
          levels.data.map((item) => ({
            key: item.id,
            text: item.name,
            value: item.id,
          }))
        );
        const types = await lookupsApi.getGroup(14);
        setTicketTypeOpts(
          types.data.filter((item) => item.id !== 26).map((item) => ({ key: item.id, text: item.name, value: item.id }))
        );

        setIsSubmitting(false);
      } catch (err) {
        setIsSubmitting(false);
        console.log('Error fetching data...', err);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    (async () => {
      if (formData.type) {
        const subTypes = await lookupsApi.getGroup(formData.type);
        setSubTypeOpts(subTypes.data.map((item) => ({ key: item.id, text: item.name, value: item.id })));
      }
    })();
  }, [formData.type]);

  useEffect(() => {
    if (location.state && location.state.sysTicketId) {
      (async () => {
        setEditMode(true);
        const ticketInfo = await customerTicketsApi.getOne(location.state.ticketId);
        const sysTicketInfo = await customerTicketsApi.getRelatedSystemTicket(location.state.sysTicketId);
        setFormData({
          type: ticketInfo.data.type,
          subType: sysTicketInfo.data.type,
          image: sysTicketInfo.data.image,
          customerId: ticketInfo.data.customerId,
          levelId: ticketInfo.data.levelId,
          note: sysTicketInfo.data.note,
          comment: 'TODO: fill on submit',
          createdBy: ticketInfo.data.createdBy,
          commentType: 29, // [EDIT NOTE]
          status: ticketInfo.data.status,
        });
      })();
    } else {
      setFormData({
        ...formData,
        createdBy: userInfo.user.id,
      });
    }
  }, [location.state, userInfo]);

  useEffect(() => {
    validateInputs();
  }, [touched, formData]);
  // End of EFFECTS //

  // EVENT Handlers //
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setTouched({
      type: true,
      subType: true,
      customerId: true,
      levelId: true,
      note: true,
    });
    setIsSubmitting(true);
    if (validateInputs()) {
      try {
        if (editMode) {
          await editForm();
        } else {
          await saveNewForm();
        }

        router.replace('/sys-tickets');
      } catch (error) {
        setIsSubmitting(false);
        setErrors({ key: 1, message: 'حدث خطأ أثناء الحفظ' });
        console.log('error saving', error);
      }
    }
    setIsSubmitting(false);
  };

  const saveNewForm = async () => {
    const data = {
      systemTicket: {
        note: formData.note,
        image: formData.image,
        type: formData.subType,
      },
      customerTicket: {
        type: formData.type,
        createdBy: formData.createdBy,
        customerId: formData.customerId,
        levelId: formData.levelId,
        status: formData.status,
      },
      ticketComment: {
        comment: formData.comment,
        type: formData.commentType,
        deletable: true,
      },
    };
    return await customerTicketsApi.postSystemTicket(data);
  };

  const editForm = async () => {
    const data = {
      note: formData.note,
      image: formData.image,
      type: formData.subType,
      createdBy: formData.createdBy,
      customerTicketId: location.state.ticketId,
    };
    return await customerTicketsApi.editSystemTicket(location.state.sysTicketId, data);
  };

  const cameraChangeHandler = (e) => {
    e.preventDefault();
    const imageSrc = webcamRef.current.getScreenshot();
    setFormData({ ...formData, image: imageSrc });
  };

  const fileChangeHandler = (e) => {
    const fileUpload = document.getElementById('fileUpload');
    if (fileUpload.files.length) {
      const extension = fileUpload.value.split('.').pop().toLowerCase();
      if (extension === 'png' || extension === 'jpg' || extension === 'jpeg') {
        setErrors([]);
        const uploadedImage = fileUpload.files[0];
        const reader = new FileReader();
        reader.onload = function (upload) {
          setFormData({
            ...formData,
            image: `data:image/jpeg;base64, ${btoa(upload.target.result)}`,
          });
        };
        reader.readAsBinaryString(uploadedImage);
      } else {
        setErrors({
          key: 1000,
          message: 'الرجاء اختيار صورة بامتداد png او jpg او jpeg',
        });
      }
    }
  };

  const handleInputChanges = (e, v) => {
    setTouched({
      ...touched,
      [v.name]: true,
    });

    setFormData({
      ...formData,
      [v.name]: v.value,
      ...(v.name === 'type' ? { subType: null } : {}),
    });
  };

  const validateInputs = () => {
    const errors = {};
    if (touched.type && !formData.type) errors.type = true;
    if (touched.subType && !formData.subType) errors.subType = true;
    if (touched.customerId && !formData.customerId) errors.customerId = true;
    if (touched.levelId && !formData.levelId) errors.levelId = true;
    if (touched.note && !formData.note) errors.note = 'يجب اضافة وصف للتذكرة';

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };
  // End of EVENT handlers //

  const panes = [
    {
      menuItem: 'صورة من الجهاز',
      render: () => <Tab.Pane>{imageFromDevice()}</Tab.Pane>,
    },
    {
      menuItem: 'صورة من الكاميرا',
      render: () => <Tab.Pane>{imageFromCamera()}</Tab.Pane>,
    },
  ];

  const imageFromDevice = () => {
    return (
      <Form.Field>
        <label>رفع صورة من الجهاز</label>
        <Form.Input type='file' id='fileUpload' onChange={fileChangeHandler} />
      </Form.Field>
    );
  };

  const imageFromCamera = () => {
    return !Boolean(formData.image) ? (
      <div className='camera-div'>
        <Webcam audio={false} ref={webcamRef} screenshotFormat='image/jpeg' className='video-div' />
        <Button fluid onClick={cameraChangeHandler}>
          التقاط صورة
        </Button>
      </div>
    ) : (
      <Button fluid onClick={() => setFormData({ ...formData, image: null })}>
        التقاط صورة جديدة
      </Button>
    );
  };

  return (
    <PageCardContainer>
      {editMode ? (
        <h2 style={{ marginBottom: '2rem' }}>تعديل تذكرة صيانة نظام # {location.state.ticketId}</h2>
      ) : (
        <h2 style={{ marginBottom: '2rem' }}>اضافة تذكرة صيانة نظام</h2>
      )}
      <Form error onSubmit={onSubmitHandler} className='add-sys-ticket-form'>
        <Form.Group widths={2}>
          <Form.Field disabled={editMode}>
            <label>العميل</label>
            <Dropdown
              placeholder='يرجى الاختيار'
              fluid
              name='customerId'
              selection
              search
              onChange={handleInputChanges}
              options={customerIdOpts}
              value={formData.customerId}
              error={validationErrors.customerId}
              disabled={editMode}
            />
          </Form.Field>
          <Form.Field>
            <label>درجة الخطورة</label>
            <Dropdown
              placeholder='يرجى الاختيار'
              name='levelId'
              fluid
              selection
              search
              onChange={handleInputChanges}
              options={levelIdOpts}
              value={formData.levelId}
              error={validationErrors.levelId}
              disabled={editMode}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths={2}>
          <Form.Field>
            <label>تاريخ انشاء التذكرة</label>
            <Form.Input className='date-input-ltr' readOnly value={moment().format('YYYY/MM/DD hh:mm A')} fluid />
          </Form.Field>

          <Form.Field disabled={editMode}>
            <label>نوع التذكرة</label>
            <Dropdown
              placeholder='يرجى الاختيار'
              fluid
              name='type'
              selection
              search
              onChange={handleInputChanges}
              options={ticketTypeOpts}
              value={formData.type}
              error={validationErrors.type}
              disabled={editMode}
            />
          </Form.Field>
          <Form.Field>
            <label>الاجراء المطلوب</label>
            <Dropdown
              placeholder='يرجى الاختيار'
              fluid
              name='subType'
              selection
              search
              onChange={handleInputChanges}
              options={subTypeOpts}
              value={formData.subType}
              error={validationErrors.subType}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <label>الوصف</label>
          <Form.TextArea
            error={validationErrors.note}
            value={formData.note}
            onChange={handleInputChanges}
            name='note'
          />
        </Form.Field>
        {!editMode ? (
          <Form.Field>
            <label>ملاحظات إضافية</label>
            <Form.Input fluid value={formData.comment} onChange={handleInputChanges} name='comment' />
          </Form.Field>
        ) : null}

        <h5>صورة إضافية</h5>
        <Tab panes={panes} />
        {formData.image && (
          <>
            <div className='image-preview-header'>عرض الصورة الاضافية</div>
            <div className='image-preview'>
              <div className='image-cropper'>
                <Image src={formData.image} className='img' centered />
              </div>
            </div>
          </>
        )}

        <Message error key={errors.key} content={errors.message} />

        <Responsive minWidth={768}>
          <div style={{ marginTop: '2rem' }}>
            <Button
              icon
              type='submit'
              loading={isSubmitting}
              disabled={isSubmitting}
              className='blue-button'
              labelPosition='right'
              style={{ marginLeft: '1rem' }}
            >
              <Icon name='save' className='blue-buton-icon' />
              {editMode ? 'تعديل' : 'حفظ'}
            </Button>

            <Button
              icon='chevron right'
              labelPosition='right'
              disabled={isSubmitting}
              content='رجوع'
              onClick={(e) => {
                e.preventDefault();
                router.goBack();
              }}
            />
          </div>
        </Responsive>
        <Responsive maxWidth={767}>
          <div style={{ marginTop: '2rem' }}>
            <Button
              icon
              fluid
              type='submit'
              loading={isSubmitting}
              disabled={isSubmitting}
              className='blue-button'
              labelPosition='right'
              style={{ marginBottom: '1rem' }}
            >
              <Icon name='save' className='blue-buton-icon' />
              {editMode ? 'تعديل' : 'حفظ'}
            </Button>

            <Button
              fluid
              icon='chevron right'
              labelPosition='right'
              disabled={isSubmitting}
              content='رجوع'
              onClick={(e) => {
                e.preventDefault();
                router.goBack();
              }}
            />
          </div>
        </Responsive>
      </Form>
    </PageCardContainer>
  );
};

export default SysTicketAddForm;
