import { combineReducers } from 'redux';
import { userAuthReducer } from './userAuthReducer';
import { ticketsReducer } from './tickets.reducer';

const rootReducer = combineReducers({
  user: userAuthReducer,
  tickets: ticketsReducer,
});

export default rootReducer;
