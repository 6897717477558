import React, { useState, useEffect } from 'react';
import PageCardContainer from '../../../Layout/PageCardContainer';
import { Form, Message, Button, Icon, Responsive, Dropdown, Table, Card, Grid, Label } from 'semantic-ui-react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { useHistory } from 'react-router-dom';
import { customerTicketsApi, maintenanceTicketsApi, trackingVehiclesApi } from './../../../../api/api';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

export default function EditMaintenanceTicket() {
  const [inputDate, setInputDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [selectedTrackingVehicleId, setSelectedTrackingVehicleId] = useState('');
  const user = useSelector((st) => st.user).user;
  const location = useLocation();
  const { id, customerTicketId } = location.state;

  const [trackingVehicleApiInputs, setTrackingVehicleApiInputs] = useState([]);
  const [trackingVehiclesSelectedList, setTrackingVehiclesSelectedList] = useState([]);
  const [trackingVehiclesDisplayOpts, setTrackingVehiclesDisplayOpts] = useState([]);
  const [trackingVehiclesBeforeEdit, setTrackingVehiclesBeforeEdit] = useState([]);

  const [formData, setFormData] = useState({
    address: '',
    createdAt: '',
    createdBy: '',
    customerId: '',
    customerName: '',
    id: '',
    note: '',
    trackingVehiclesList: [],
  });

  const [validationErrors, setValidationErrors] = useState({
    address: null,
    note: null,
  });

  const [touched, setTouched] = useState({
    address: false,
    note: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errors, setErrors] = useState({});

  const router = useHistory();

  const validation = () => {
    const errors = {
      address: false,
      note: false,
    };

    if (!formData.address) {
      errors.address = 'الرجاء ادخال العنوان';
    }

    if (!formData.note) {
      errors.note = 'الرجاء ادخال الوصف';
    }

    setValidationErrors(errors);

    if (validationErrors.address || validationErrors.note) return false;
    else return true;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await maintenanceTicketsApi.getOneMaintenanceTicket(id);
        setFormData(res.data);
        setInputDate(new Date(res.data.visitDate));

        // loop over res.data.trackings and store them with their status
        let selectedTvArr = res.data.trackingVehiclesList.length
          ? res.data.trackingVehiclesList.map((item) => ({
              id: item.trackingVehicleId,
              text: `${item.plateNumber} - ${item.imei}`,
              key: item.trackingVehicleId,
              // description = ticket's status
              description: item.status,
            }))
          : '';

        setTrackingVehiclesSelectedList(selectedTvArr);
        setTrackingVehiclesBeforeEdit(selectedTvArr);

        //get all tracking vehicles for a certain customer
        const trackingVId = await trackingVehiclesApi.getTrackingVehiclesList(res.data.customerId);
        let trackingApiOpts = trackingVId.data.map((item) => ({ key: item.id, text: item.name, value: item.id }));
        setTrackingVehicleApiInputs(trackingApiOpts);

        let displayed = trackingApiOpts.filter(
          (apiItem) => !selectedTvArr.some((selectedItem) => selectedItem.key === apiItem.key)
        );
        setTrackingVehiclesDisplayOpts([...displayed]);

        setFormData(res.data);
      } catch (err) {
        setIsSubmitting(false);
        console.log('Error fetching data...', err);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    let displayed = trackingVehicleApiInputs.filter(
      (apiItem) => !trackingVehiclesSelectedList.some((selectedItem) => selectedItem.key === apiItem.key)
    );
    setTrackingVehiclesDisplayOpts([...displayed]);
  }, [trackingVehiclesSelectedList]);

  useEffect(() => {
    validation();
  }, [formData]);

  const onChangeHandler = (e) => {
    setTouched({
      ...touched,
      [e.target.name]: true,
    });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let errorsVar = {};

    setTouched({
      address: true,
      note: true,
    });

    if (!trackingVehiclesSelectedList.length) {
      errorsVar = { key: 0, message: 'لم يتم اضافة جهاز تتبع - مركبة للتذكرة' };
    } else {
      errorsVar = {};
    }
    setErrors(errorsVar);

    if (_.isEmpty(errorsVar) && validation()) {
      try {
        //send only maintenanceVehicleIds that are open (to delete and insert them in backend)
        let tvList = trackingVehiclesSelectedList.filter((x) => x.key && x.description === 18).map((x) => x.key);

        let added = trackingVehiclesSelectedList.filter(
          (apiItem) => !trackingVehiclesBeforeEdit.some((selectedItem) => selectedItem.key === apiItem.key)
        );

        let removed = trackingVehiclesBeforeEdit.filter(
          (apiItem) => !trackingVehiclesSelectedList.some((selectedItem) => selectedItem.key === apiItem.key)
        );

        const commentStr = `
        التعديلات على اجهزة التتبع - المركبات:
        ${
          added.length
            ? `تم اضافة التالي:
          ${added.map((item) => {
            return item.text + `\n`;
          })}`
            : ``
        }
        ${
          removed.length
            ? `تم حذف التالي:
          ${removed.map((item) => {
            return item.text + `\n`;
          })}`
            : ``
        }
        `;

        // if any maintenanceTicketsVehicles are added/removed, add a comment
        if (removed.length || added.length) {
          await customerTicketsApi.postComment({
            createdBy: user.id,
            comment: commentStr,
            type: 29,
            deletable: false,
            customerTicketId,
          });
        }

        const data = {
          note: formData.note,
          address: formData.address,
          visitDate: formData.visitDate,
          createdBy: Number(user.id),
          trackingVehicleIds: tvList,
          customerTicketId: customerTicketId,
          editComment: removed.length || added.length ? false : true,
        };

        await maintenanceTicketsApi.editMaintenanceTicket(id, data);
        router.goBack();
      } catch (e) {
        console.log('Error editing maintenance ticket data...', e);
        setIsSubmitting(false);
        setErrors({ key: 1, message: 'خطأ اثناء الحفظ' });
      }
    } else {
      setIsSubmitting(false);
    }
  };

  const DateChangeHandler = (date) => {
    setInputDate(date);
    setFormData({
      ...formData,
      visitDate: moment.utc(date.toLocaleDateString()).format('YYYY-MM-DD'),
    });
  };

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  const selectionChangeHandler = async (e, data) => {
    if (data.name === 'trackingVehicleId') {
      setSelectedTrackingVehicleId(data.value);
    }

    setFormData({ ...formData, [data.name]: data.value });
  };

  const addTrackingVehicleHandler = (e) => {
    e.preventDefault();
    if (trackingVehiclesSelectedList.filter((x) => x.key === selectedTrackingVehicleId).length) {
    } else {
      let arr = [
        ...trackingVehiclesSelectedList,
        { ...trackingVehicleApiInputs.filter((x) => x.key === selectedTrackingVehicleId)[0], description: 18 },
      ];
      setTrackingVehiclesSelectedList([...arr]);
    }
  };

  const handleTvRemoveBtn = (e, id) => {
    e.preventDefault();
    let arr = trackingVehiclesSelectedList.filter((x) => x.key !== id);
    setTrackingVehiclesSelectedList([...arr]);
  };

  return (
    <div className='maintenance-ticket-form'>
      <PageCardContainer>
        <div style={{ margin: '1rem' }} className='form-margin'>
          <h2 style={{ marginBottom: '2rem' }}>تعديل تذكرة صيانة جهاز تتبع</h2>
          <Grid stackable className='edit-grid'>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <label>
                <b>العميل</b>
              </label>
              <p>{formData.customerName}</p>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <label>
                <b>تاريخ انشاء التذكرة</b>
              </label>
              <p className='ltr'>{moment(formData.createdAt).format('YYYY-MM-DD, hh:mm A')}</p>
            </Grid.Column>
          </Grid>
          <Form error style={{ margin: '1rem' }} onSubmit={onSubmitHandler}>
            <Form.Group widths={2}>
              <Form.Field>
                <label>تاريخ الزيارة</label>
                <DatePicker
                  selected={inputDate}
                  onChange={(date) => DateChangeHandler(date)}
                  maxDate={moment().toDate()}
                  onChangeRaw={handleDateChangeRaw}
                />
              </Form.Field>
              <Form.Field>
                <label>العنوان</label>
                <Form.Input
                  value={formData['address']}
                  name='address'
                  onChange={onChangeHandler}
                  error={touched.address && validationErrors.address}
                />
              </Form.Field>
            </Form.Group>

            <Form.Field>
              <label>الوصف</label>
              <Form.TextArea
                value={formData['note']}
                name='note'
                onChange={onChangeHandler}
                error={touched.note && validationErrors.note}
              />
            </Form.Field>

            <h4>اضافة اجهزة تتبع</h4>

            <div className='add-device'>
              <Form.Field>
                <label>جهاز التتبع - المركبة</label>
                <Dropdown
                  name='trackingVehicleId'
                  placeholder={
                    !trackingVehiclesDisplayOpts.length
                      ? 'لا توجد اجهزة تتبع مرتبطة بمركبت لهذا العميل'
                      : 'اختر الارتباط'
                  }
                  fluid
                  selection
                  search
                  onChange={selectionChangeHandler}
                  options={trackingVehiclesDisplayOpts}
                  value={formData.trackingVehicleId}
                  error={touched.trackingVehicleId && validationErrors.trackingVehicleId}
                  disabled={!trackingVehiclesDisplayOpts.length ? true : false}
                />
              </Form.Field>

              <Button
                type='submit'
                icon='plus'
                style={{ width: '40px', height: '40px' }}
                disabled={!trackingVehiclesDisplayOpts.length ? true : false}
                onClick={(e) => addTrackingVehicleHandler(e)}
              />
            </div>

            {trackingVehiclesSelectedList.length ? (
              <div className='added-tracking-vehicles'>
                <h4>قائمة اجهزة التتبع المضافة</h4>
                <Responsive maxWidth={767}>
                  <div className='responsive-cards'>
                    <Card.Group>
                      {trackingVehiclesSelectedList.map((item, index) => {
                        return (
                          <Card fluid key={index}>
                            <Card.Content>
                              <Card.Description>
                                <div className='responsive-card-wrapper'>
                                  <div className='cards-table'>
                                    <Table basic='very' unstackable compact>
                                      <Table.Body>
                                        <Table.Row>
                                          <Table.Cell width={5}>
                                            <span>IMEI:</span>
                                          </Table.Cell>
                                          <Table.Cell>
                                            <b>{item.text.split('#')[0]}</b>
                                          </Table.Cell>
                                        </Table.Row>

                                        <Table.Row>
                                          <Table.Cell width={5}>
                                            <span>رقم لوحة المركبة:</span>
                                          </Table.Cell>
                                          <Table.Cell>
                                            <b>{item.text.split('#')[1]}</b>
                                          </Table.Cell>
                                        </Table.Row>
                                      </Table.Body>
                                    </Table>
                                  </div>
                                  <div>
                                    {item.description === 18 ? (
                                      ''
                                    ) : (
                                      <Button
                                        icon='x'
                                        color='red'
                                        onClick={(e) => handleTvRemoveBtn(e, item.key)}
                                        size='tiny'
                                      />
                                    )}
                                  </div>
                                </div>
                              </Card.Description>
                            </Card.Content>
                          </Card>
                        );
                      })}
                    </Card.Group>
                  </div>
                </Responsive>
                <Responsive minWidth={768}>
                  <Table celled size='small' compact>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>IMEI</Table.HeaderCell>
                        <Table.HeaderCell>رقم لوحة المركبة</Table.HeaderCell>
                        <Table.HeaderCell>حالة التذكرة</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {trackingVehiclesSelectedList.map((item, index) => {
                        return (
                          <Table.Row key={item.key}>
                            <Table.Cell>{item.text.split('#')[0]}</Table.Cell>
                            <Table.Cell>{item.text.split('#')[1]}</Table.Cell>
                            {item.description === 18 ? (
                              <>
                                <Table.Cell>
                                  <Label color='orange' size='small'>
                                    <Icon name='clock outline' /> مفتوحة
                                  </Label>
                                </Table.Cell>
                                <Table.Cell>
                                  <Button
                                    icon='x'
                                    color='red'
                                    onClick={(e) => handleTvRemoveBtn(e, item.key)}
                                    size='tiny'
                                  />
                                </Table.Cell>
                              </>
                            ) : (
                              <>
                                <Table.Cell>
                                  <Label color='green'>
                                    <Icon name='check circle outline' /> مغلقة
                                  </Label>
                                </Table.Cell>
                                <Table.Cell></Table.Cell>
                              </>
                            )}
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                </Responsive>
              </div>
            ) : (
              ''
            )}

            <Message error key={errors.key} content={errors.message} />

            <Responsive minWidth={768}>
              <div style={{ marginTop: '2rem' }}>
                <Button
                  icon
                  type='submit'
                  loading={isSubmitting}
                  className='blue-button'
                  labelPosition='right'
                  style={{ marginLeft: '1rem' }}
                >
                  <Icon name='save' className='blue-buton-icon' />
                  حفظ
                </Button>

                <Button
                  icon='chevron right'
                  labelPosition='right'
                  content='رجوع'
                  onClick={(e) => {
                    e.preventDefault();
                    router.goBack();
                  }}
                />
              </div>
            </Responsive>
            <Responsive maxWidth={767}>
              <div style={{ marginTop: '2rem' }}>
                <Button
                  icon
                  fluid
                  type='submit'
                  loading={isSubmitting}
                  className='blue-button'
                  labelPosition='right'
                  style={{ marginBottom: '1rem' }}
                >
                  <Icon name='save' className='blue-buton-icon' />
                  حفظ
                </Button>

                <Button
                  fluid
                  icon='chevron right'
                  labelPosition='right'
                  content='رجوع'
                  onClick={(e) => {
                    e.preventDefault();
                    router.goBack();
                  }}
                />
              </div>
            </Responsive>
          </Form>
        </div>
      </PageCardContainer>
    </div>
  );
}
