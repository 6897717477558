import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Icon, Loader } from 'semantic-ui-react';
import { customerTicketsApi } from '../../../../api/api';
import './SystemTicketContent.scss';

const SystemTicketContent = ({ systemTicketId }) => {
  const [ticket, setTicket] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fullscreenImage, setFullscreenImage] = useState(false);

  useEffect(() => {
    if (systemTicketId) fetchSystemTicket(systemTicketId);
  }, [systemTicketId]);

  const fetchSystemTicket = async (id) => {
    setLoading(true);
    const { data: ticket } = await customerTicketsApi.getRelatedSystemTicket(id);
    setTicket(ticket);
    setLoading(false);
  };

  return (
    <>
      <AnimatePresence>
        {!loading && ticket && fullscreenImage && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.4 } }}
            exit={{ opacity: 0, transition: { duration: 0.4 } }}
            className='ticket-img-wrppaer-fullscreen'
            onClick={() => setFullscreenImage(false)}
          >
            <img src={ticket.image} alt='' className='ticket-img-fullscreen' />
          </motion.div>
        )}
      </AnimatePresence>
      <div className='system-ticket-content-wrapper'>
        <h4>+ تفاصيل التذكرة</h4>
        {!loading ? (
          ticket && (
            <>
              <h5 className='title'>نوع الاجراء</h5>
              <p className='type-name'>{ticket.typeName}</p>
              <h5 className='title'>الوصف</h5>
              <p className='content'>{ticket.note}</p>
              {Boolean(ticket.image) && (
                <>
                  <h5 className='title'>صورة إضافية</h5>
                  <div className='ticket-img-wrapper' onClick={() => setFullscreenImage(true)}>
                    <Icon name='expand' size='large' />
                    <img src={ticket.image} alt='' className='ticket-img' />
                  </div>
                </>
              )}
            </>
          )
        ) : (
          <Loader active inline size='large' />
        )}
      </div>
    </>
  );
};

export default SystemTicketContent;
