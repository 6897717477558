import React from 'react';
import CustomerTicketsSearch from './CustomerTicketsSearch';
import CustomerTicketsList from './CustomerTicketsList';
import './CustomerTickets.scss';
import { AnimatePresence, motion } from 'framer-motion';
import { SysTicketDetailsView } from '../SystemMaintenanceTickets/SysTicketDetailsView';
import { Player } from '@lottiefiles/react-lottie-player';
import { Button, Icon, Popup } from 'semantic-ui-react';

const CustomerTickets = ({
  loading,
  fetchData,
  data,
  onTicketSelected,
  handleDeleteTicket,
  onTicketStatusChanged,
  exportFileHandler,
  selectedTicket,
}) => {
  return (
    <div className='customer-tickets-container'>
      <div className='tickets-menu'>
        <div>
          <div className='top-container'>
            <h2 className='main-title'>تذاكر الصيانة</h2>
            <Popup
              content='تصدير التذاكر الى ملف اكسل'
              trigger={
                <Button icon className='excel-button' basic onClick={() => exportFileHandler()}>
                  <Icon name='file excel' />
                </Button>
              }
            />
          </div>
          <hr className='ct-divider' />
          <CustomerTicketsSearch
            onSearch={(filters) => {
              fetchData(filters);
            }}
            loading={loading}
          />
          <CustomerTicketsList data={data} onTicketSelected={onTicketSelected} loading={loading} />
        </div>
      </div>
      <div className='ticket-details'>
        <AnimatePresence exitBeforeEnter key={selectedTicket}>
          {Boolean(selectedTicket) ? (
            {
              15: (
                <SysTicketDetailsView
                  onDelete={() => handleDeleteTicket()}
                  onStatusChanged={onTicketStatusChanged}
                  key={selectedTicket.id}
                  ticket={selectedTicket}
                />
              ),
              16: (
                <SysTicketDetailsView
                  onDelete={() => handleDeleteTicket()}
                  onStatusChanged={onTicketStatusChanged}
                  key={selectedTicket.id}
                  ticket={selectedTicket}
                />
              ),
              26: (
                <SysTicketDetailsView
                  onDelete={() => handleDeleteTicket()}
                  onStatusChanged={onTicketStatusChanged}
                  key={selectedTicket.id}
                  ticket={selectedTicket}
                />
              ),
            }[selectedTicket.type]
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.5 } }}
              exit={{ opacity: 0 }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Player autoplay loop src='https://assets9.lottiefiles.com/packages/lf20_hXvfqN.json' />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default CustomerTickets;
