import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Message, Button, Dropdown, Icon, Responsive, Confirm, Modal } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import './UserAuth.scss';
import { lookupsApi, usersApi } from '../../api/api';

export default function Signup() {
  const [formData, setFormData] = useState({
    username: '',
    fullname: '',
    password: '',
    passwordConfirm: '',
    phone: '',
    email: '',
    jobtitle: '',
    image: '',
    role: null,
    verified: false,
  });

  const [validationErrors, setValidationErrors] = useState({
    username: null,
    fullname: null,
    password: null,
    passwordConfirm: null,
    phone: null,
    email: null,
    image: null,
  });

  const [touched, setTouched] = useState({
    username: false,
    fullname: false,
    password: false,
    passwordConfirm: false,
    phone: false,
    email: false,
    image: false,
  });

  const [jobTitleOptions, setJobTitleOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState([]);
  const [userCreated, setUserCreated] = useState(false);

  const router = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      const res = await lookupsApi.getGroup(2);
      //loop over the data and store in an array of objects for select options
      let options = res.data.map((item) => ({ key: item.id, text: item.name, value: item.id }));
      setJobTitleOptions(options);
      setFormData({ ...formData, jobtitle: options[0].key });
    };

    fetchData();
  }, []);

  const validation = () => {
    const errors = {
      username: null,
      fullname: null,
      password: null,
      passwordConfirm: null,
      phone: null,
      email: null,
      image: null,
    };

    //USERNAME VALIDATION
    if (!formData.username) {
      errors.username = 'الرجاء ادخال اسم المستخدم';
    } else if (formData.username.length < 3) {
      errors.username = 'اسم المستخدم يجب ان يكون 3 احرف على الاقل';
    } else if (/[\u0621-\u064A\s]+/.test(formData.username)) {
      errors.username = 'اسم المستخدم يجب ان يحتوي على احرف انجليزية وارقام و رموز فقط';
    }

    //FULLNAME VALIDATION
    if (!formData.fullname) {
      errors.fullname = 'الرجاء ادخال الاسم الكامل';
    } else if (formData.fullname.length < 3) {
      errors.fullname = 'الاسم الكامل يجب ان يكون 3 احرف على الاقل';
    }

    //PASSWORD VALIDATION
    if (!formData.password) {
      errors.password = 'الرجاء ادخال كلمة السر';
    } else if (formData.password.length < 3) {
      errors.password = 'كلمة السر يجب ان تكون 3 احرف على الاقل';
    }

    //PASSWORD CONFIRMATION VALIDATION
    if (!formData.passwordConfirm) {
      errors.passwordConfirm = 'الرجاء ادخال تأكيد كلمة السر';
    } else if (formData.password.length < 3 || formData.password !== formData.passwordConfirm) {
      errors.passwordConfirm = 'كلمة السر غير متطابقة';
    }

    //EMAIL VALIDATION
    if (!formData.email) {
      errors.email = 'الرجاء ادخال الايميل';
    } else if (!formData.email.includes('@') || /[\u0621-\u064A\s]+/.test(formData.email)) {
      errors.email = 'الرجاء ادخال ايميل صحيح';
    }

    //PHONE VALIDATION
    if (!formData.phone) {
      errors.phone = 'الرجاء ادخال رقم الهاتف';
    } else if (formData.phone.length < 7 || formData.phone.length > 15 || !/^[0-9]+$/.test(formData.phone)) {
      errors.phone = 'الرجاء ادخال رقم هاتف صحيح';
    }

    setValidationErrors(errors);

    if (
      validationErrors.username ||
      validationErrors.fullname ||
      validationErrors.password ||
      validationErrors.passwordConfirm ||
      validationErrors.phone ||
      validationErrors.email ||
      validationErrors.image
    )
      return false;
    else return true;
  };

  useEffect(() => {
    validation();
  }, [formData]);

  const onChangeHandler = (e) => {
    setTouched({
      ...touched,
      [e.target.name]: true,
    });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const selectionChangeHandler = (e, data) => {
    setFormData({ ...formData, jobtitle: data.value });
  };

  const fileChangeHandler = (e) => {
    const fileUpload = document.getElementById('fileUpload');
    if (fileUpload.files.length) {
      const extension = fileUpload.value.split('.').pop().toLowerCase();
      if (extension === 'png' || extension === 'jpg' || extension === 'jpeg') {
        setErrors([]);
        const uploadedImage = fileUpload.files[0];
        const reader = new FileReader();
        reader.onload = function (upload) {
          setFormData({ ...formData, image: btoa(upload.target.result) });
        };
        reader.readAsBinaryString(uploadedImage);
      } else {
        setErrors([
          {
            key: 1000,
            message: 'الرجاء اختيار صورة بامتداد png او jpg او jpeg',
          },
        ]);
      }
    }
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    setTouched({
      username: true,
      fullname: true,
      password: true,
      passwordConfirm: true,
      phone: true,
      email: true,
    });

    if (validation()) {
      try {
        await usersApi.postUser(formData);
        setUserCreated(true);
      } catch (e) {
        setIsSubmitting(false);
        let errorsList = [];
        if (e.response && e.response.status === 400 && e.response.data) {
          if (e.response.data.username) errorsList.push({ key: 0, message: 'اسم المستخدم مكرر' });
          if (e.response.data.phone) errorsList.push({ key: 1, message: 'رقم الهاتف مكرر' });
          if (e.response.data.email) errorsList.push({ key: 2, message: 'الايميل مكرر' });
          setErrors(errorsList);
        } else {
          setErrors([{ key: 0, message: 'خطأ اثناء الحفظ' }]);
        }
      }
    } else {
      setIsSubmitting(false);
    }
  };

  return (
    <div className='login-bg'>
      <Modal
        content='تم ارسال طلب انشاء الحساب - سيتم تأكيد حسابكم في أقرب وقت'
        actions={[{ content: 'حسناً', color: 'blue' }]}
        open={userCreated}
        onClose={router.goBack}
      />
      <div className='signup-card'>
        <div className='signup-contents form-margin'>
          <h2 style={{ marginBottom: '2rem' }}>تسجيل حساب جديد</h2>
          <Form error style={{ margin: '1rem' }} onSubmit={onSubmitHandler}>
            <Form.Group widths={2}>
              <Form.Field>
                <label>اسم المستخدم</label>
                <Form.Input
                  icon='user'
                  value={formData['username']}
                  name='username'
                  onChange={onChangeHandler}
                  error={touched.username && validationErrors.username}
                />
              </Form.Field>

              <Form.Field>
                <label>الاسم الكامل</label>
                <Form.Input
                  icon='user circle'
                  value={formData['fullname']}
                  name='fullname'
                  onChange={onChangeHandler}
                  error={touched.fullname && validationErrors.fullname}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths={2}>
              <Form.Field>
                <label>كلمة السر</label>
                <Form.Input
                  icon='lock'
                  type='password'
                  value={formData['password']}
                  name='password'
                  onChange={onChangeHandler}
                  error={touched.password && validationErrors.password}
                />
              </Form.Field>

              <Form.Field>
                <label>تأكيد كلمة السر</label>
                <Form.Input
                  icon='lock'
                  type='password'
                  value={formData['passwordConfirm']}
                  name='passwordConfirm'
                  onChange={onChangeHandler}
                  error={touched.passwordConfirm && validationErrors.passwordConfirm}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths={2}>
              <Form.Field>
                <label>الهاتف</label>
                <Form.Input
                  icon='phone'
                  value={formData['phone']}
                  name='phone'
                  onChange={onChangeHandler}
                  error={touched.phone && validationErrors.phone}
                />
              </Form.Field>

              <Form.Field>
                <label>الايميل</label>
                <Form.Input
                  icon='mail'
                  type='email'
                  value={formData['email']}
                  name='email'
                  onChange={onChangeHandler}
                  error={touched.email && validationErrors.email}
                />
              </Form.Field>
            </Form.Group>

            <Form.Field>
              <label>المسمى الوظيفي</label>
              <Dropdown
                placeholder='اختر المسمى الوظيفي'
                fluid
                selection
                search
                value={formData['jobtitle']}
                options={jobTitleOptions}
                onChange={selectionChangeHandler}
                className='table-dropdown'
                style={{ marginLeft: '0.5rem' }}
              />
            </Form.Field>

            <Form.Field>
              <label>الصورة</label>
              <Form.Input
                type='file'
                id='fileUpload'
                onChange={fileChangeHandler}
                error={touched.image && validationErrors.image}
              />
            </Form.Field>

            {errors.map((item) => (
              <Message error key={item.key} content={item.message} />
            ))}

            <Responsive minWidth={768}>
              <div style={{ marginTop: '2rem' }}>
                <Button
                  icon
                  type='submit'
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  className='blue-button'
                  labelPosition='right'
                  style={{ marginLeft: '1rem' }}
                >
                  <Icon name='save' className='blue-buton-icon' />
                  حفظ
                </Button>

                <Button
                  icon='chevron right'
                  labelPosition='right'
                  content='رجوع'
                  onClick={(e) => {
                    e.preventDefault();
                    router.goBack();
                  }}
                />
              </div>
            </Responsive>
            <Responsive maxWidth={767}>
              <div style={{ marginTop: '2rem' }}>
                <Button
                  icon
                  fluid
                  type='submit'
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  className='blue-button'
                  labelPosition='right'
                  style={{ marginBottom: '1rem' }}
                >
                  <Icon name='save' className='blue-buton-icon' />
                  حفظ
                </Button>

                <Button
                  fluid
                  icon='chevron right'
                  labelPosition='right'
                  content='رجوع'
                  onClick={(e) => {
                    e.preventDefault();
                    router.goBack();
                  }}
                />
              </div>
            </Responsive>
          </Form>
        </div>
      </div>
    </div>
  );
}
