import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Dropdown, Icon, Input, Popup, Modal, Transition, Responsive } from 'semantic-ui-react';
import { customerTicketsApi } from '../../../api/api';
import userImage from '../../../assets/images/user-icon.png';
import AnimatedModal from '../../Reusable/AnimatedModal';
import EmojiPicker from '../../Reusable/EmojiPicker';
import MaintenanceTicketContent from './MaintenanceTicket/MaintenanceTicketContent';
import SystemTicketContent from './SysTicketContents/SystemTicketContent';
import './SysTicketDetailsView.scss';
import SysTicketInfoPanel from './SysTicketInfoPanel';
import TicketCommentMessages from './TicketCommentMessages';
import { maintenanceTicketsApi } from './../../../api/api';
import PermissionCheck from '../../Reusable/PermissionCheck';
import permissions, { hasPermission } from '../../../helpers/permissions';
import qs from 'qs';

// TODO: Find a way to keep these lookups consistant across deployments
const statusColor = {
  18: { color: 'orange', icon: 'clock outline' }, // Open
  19: { color: 'blue', icon: 'sync' }, // In Progress
  20: { color: 'green', icon: 'check circle outline' }, // Closed
};

export const SysTicketDetailsView = ({ ticket, onStatusChanged, onDelete, onTicketSelected }) => {
  const router = useHistory();

  const userInfo = useSelector((st) => st.user);
  const [emojiVisible, setEmojiVisible] = useState(false);
  const [commentInput, setCommentInput] = useState('');
  const [ticketComments, setTicketComments] = useState([]);
  const [sendingCommentLoading, setSendingCommentLoading] = useState(false);
  const [selectedNewTicketStatus, setSelectedNewTicketStatus] = useState(undefined);
  const [editStatusModalAttr, setEditStatusModalAttr] = useState({
    visible: false,
    loading: false,
    position: { x: 0, y: 0 },
  });
  const [deleteTicketErrorPopup, setDeleteTicketErrorPopup] = useState(false);
  const [deleteTicketConfirmPopup, setDeleteTicketConfirmPopup] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteErrors, setDeleteErrors] = useState([]);

  const [maintenanceTrackingVehicles, setMaintenanceTrackingVehicles] = useState([]);

  const loadTicketComments = async (customerTicketId) => {
    try {
      const comments = await customerTicketsApi.getTicketComments(customerTicketId);
      setTicketComments(comments.data);
    } catch (error) {
      console.log('Error fetching ticket comments...', error);
    }
  };

  useEffect(() => {
    loadTicketComments(ticket.id);
  }, []);

  const onCommentSubmit = async (e) => {
    e.preventDefault();
    try {
      setSendingCommentLoading(true);
      const commentData = {
        comment: commentInput,
        type: 28,
        createdBy: userInfo.user.id,
        deletable: true,
        customerTicketId: ticket.id,
      };
      await customerTicketsApi.postComment(commentData);
      await loadTicketComments(ticket.id);
      setCommentInput('');
      setSendingCommentLoading(false);
    } catch (error) {
      setSendingCommentLoading(false);
      console.log('Error posting comments...', error);
      alert('حدث خطأ أثناء الارسال');
    }
  };

  const onTicketEditClicked = () => {
    // TODO: Show confirmation message first
    if (ticket.type === 26) {
      router.push('/maintenanceTicket/edit', { id: ticket.relatedTicketId, customerTicketId: ticket.id });
    } else {
      router.push('/sys-tickets/add', { ticketId: ticket.id, sysTicketId: ticket.relatedTicketId });
    }
  };

  const onTicketDeleteClicked = async () => {
    let deleteErrorsArr = [];
    // check if all the comments on this ticket are made by the same user
    ticketComments.forEach((comment) => {
      if (comment.createdById !== Number(userInfo.user.id)) {
        deleteErrorsArr[0] = 'توجد اجراءات او ملاحظات من قبل مستخدمين اخرين على التذكرة';
      }
    });
    // if a ticket is a maintenanceTicket, check if all maintenanceTicketVehicles are open
    if (ticket.type === 26) {
      const maintenanceTickets = await maintenanceTicketsApi.getOneMaintenanceTicket(ticket.relatedTicketId);
      maintenanceTickets.data.trackingVehiclesList.forEach((tv) => {
        if (tv.status !== 18) deleteErrorsArr[1] = 'توجد تقارير اجهزة تتبع مغلقة لهذه التذكرة';
      });
    }

    setDeleteErrors(deleteErrorsArr);

    if (deleteErrorsArr.length) setDeleteTicketErrorPopup(true);
    else setDeleteTicketConfirmPopup(true);
  };

  const deleteTicketHandler = async () => {
    try {
      const queryString = qs.stringify(
        { id: ticket.id, mtId: ticket.type === 26 ? ticket.relatedTicketId : -1 },
        { addQueryPrefix: true }
      );

      await customerTicketsApi.delete(queryString);
      await customerTicketsApi.postComment({
        createdBy: userInfo.user.id,
        comment: 'تم حذف التذكرة',
        type: 39,
        deletable: false,
        customerTicketId: ticket.id,
      });

      setDeleteSuccess(true);
    } catch (e) {
      console.log('Error deleting ticket...', e);
    }
  };

  const onTicketEditStatusClicked = (e) => {
    setEditStatusModalAttr({
      visible: true,
      loading: false,
      position: {
        x: -window.innerWidth / 2 + e.screenX,
        y: -window.innerHeight / 2 + e.screenY - 80, // 80: half the modal height,
      },
    });
  };

  const onChangeTicketStatus = async () => {
    // if user is closing the ticket check if there are any open maintenanceTrackingVehicles
    let errFound =
      selectedNewTicketStatus === 20 && Boolean(maintenanceTrackingVehicles.find((item) => item.status === 18));

    if (errFound) {
      setDeleteTicketErrorPopup(true);
      setDeleteErrors(['توجد اجهزة تتبع مفتوحة الحالة لهذه التذكرة']);
    } else {
      setEditStatusModalAttr({ ...editStatusModalAttr, loading: true });
      // TODO: try catch and show propper error message
      await customerTicketsApi.changeStatus(ticket.id, {
        userId: userInfo.user.id,
        oldStatus: ticket.status,
        newStatus: selectedNewTicketStatus,
      });
      if (typeof onStatusChanged === 'function') onStatusChanged(selectedNewTicketStatus);
      loadTicketComments(ticket.id);
      setSelectedNewTicketStatus(undefined);
      setEditStatusModalAttr({ ...editStatusModalAttr, visible: false, loading: false });
      setDeleteErrors([]);
    }
  };

  return (
    <>
      <motion.div
        className='sys-ticket-details-wrapper'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.5 } }}
        exit={{ opacity: 0 }}
      >
        <AnimatedModal
          initialPosition={editStatusModalAttr.position}
          open={editStatusModalAttr.visible}
          content={
            <>
              <h4>تعديل حالة التذكرة من {ticket.statusName} الى </h4>
              <Dropdown
                selection
                fluid
                search
                placeholder='الحالة الجديدة'
                value={selectedNewTicketStatus}
                onChange={(e, v) => setSelectedNewTicketStatus(v.value)}
                options={[
                  { key: 1, text: 'مفتوحة', value: 18 },
                  { key: 2, text: 'قيد التنفيذ', value: 19 },
                  { key: 3, text: 'مغلقة', value: 20 },
                ]}
              />
            </>
          }
          footer={
            <>
              <Button
                labelPosition='left'
                content='تأكيد'
                icon='check'
                color='blue'
                onClick={onChangeTicketStatus}
                loading={editStatusModalAttr.loading}
                disabled={editStatusModalAttr.loading}
              />
              <Button
                labelPosition='left'
                content='إلغاء'
                icon='close'
                color='grey'
                onClick={() => {
                  setEditStatusModalAttr({ ...editStatusModalAttr, visible: false });
                  setSelectedNewTicketStatus(undefined);
                }}
                disabled={editStatusModalAttr.loading}
              />
            </>
          }
        />
        <div className='sys-ticket-details'>
          <Responsive maxWidth={767} onClick={() => onTicketSelected(null)}>
            <Icon size='large' name='angle right' />
            <label style={{ fontWeight: 'bold', fontSize: 16 }}>عودة</label>
          </Responsive>
          <div className='ticket-main'>
            <div className='header-left'>
              <div className='titles'>
                <h4 className='ticket-no'>{`تذكرة رقم #${ticket.id}`}</h4>
                <h4>{ticket.typeName}</h4>
              </div>
              {ticket.status !== 20 && (
                <div>
                  <PermissionCheck permissions={permissions.EDIT_MAINTENANCE_TICKETS}>
                    <Popup
                      content='تعديل التذكرة'
                      trigger={<Icon className='header-icon' name='edit outline' onClick={onTicketEditClicked} />}
                    />
                  </PermissionCheck>
                  <PermissionCheck permissions={permissions.DELETE_TICKET}>
                    <Popup
                      content='إلغاء التذكرة'
                      trigger={
                        <Icon className='header-icon' name='trash alternate outline' onClick={onTicketDeleteClicked} />
                      }
                    />
                  </PermissionCheck>
                </div>
              )}
            </div>
            <div
              className={
                ticket.status !== 20 && hasPermission(permissions.ADD_TICKET_NOTES)
                  ? `ticket-contents ticket-content-height-comment`
                  : `ticket-contents ticket-content-height-full`
              }
            >
              <div className='ticket-customer-info'>
                {ticket &&
                  {
                    15: <SystemTicketContent systemTicketId={ticket.relatedTicketId} />,
                    16: <SystemTicketContent systemTicketId={ticket.relatedTicketId} />,
                    26: (
                      <MaintenanceTicketContent
                        maintenanceTicketId={ticket.relatedTicketId}
                        customerId={ticket.customerId}
                        customerTicketId={ticket.id}
                        ticketStatus={ticket.status}
                        maintenanceTicketTV={(dataObj) => setMaintenanceTrackingVehicles(dataObj)}
                      />
                    ),
                  }[ticket.type]}
              </div>
              <div className='comment-box'>
                <div className='comments'>
                  <TicketCommentMessages comments={ticketComments} />
                </div>
              </div>
            </div>
            {ticket.status !== 20 /* closed */ && (
              <PermissionCheck permissions={permissions.ADD_TICKET_NOTES} showWarning={<></>}>
                <form className='comment-inputs' onSubmit={onCommentSubmit}>
                  <>
                    <img className='user-img' src={userInfo.image ? userInfo.image : userImage} alt='' />
                    <Input
                      value={commentInput}
                      onChange={(e, v) => setCommentInput(v.value)}
                      transparent
                      placeholder='أضف ملاحظة...'
                      required
                      loading={sendingCommentLoading}
                    />
                    {emojiVisible && (
                      <EmojiPicker
                        onSelect={(chosenEmoji) => {
                          setEmojiVisible(false);
                          setCommentInput(commentInput + chosenEmoji.native);
                        }}
                      />
                    )}
                    <Icon
                      name={emojiVisible ? 'cancel' : 'smile outline'}
                      size='large'
                      onClick={() => setEmojiVisible(!emojiVisible)}
                    />
                    <Button
                      loading={sendingCommentLoading}
                      disabled={sendingCommentLoading}
                      className='send-btn'
                      size='mini'
                      icon='send'
                      circular
                      color='blue'
                    />
                  </>
                </form>
              </PermissionCheck>
            )}
          </div>
          <div className='ticket-side'>
            <div className='header-right'>
              <div className='titles'>
                <Icon name={statusColor[ticket.status].icon} size='large' color={statusColor[ticket.status].color} />
                <div className='status-box'>
                  <h5 className='title'>حالة التذكرة</h5>
                  <h5 className={`status status-${statusColor[ticket.status].color}`}>{ticket.statusName}</h5>
                </div>
              </div>
              <PermissionCheck permissions={permissions.EDIT_TICKET_STATUS}>
                {(ticket.status !== 20 || hasPermission(permissions.EDIT_TICKET_STATUS_AFTER_CLOSING)) && (
                  <Popup
                    content='تعديل التذكرة'
                    trigger={
                      <Icon className='change-status-icon' name='edit outline' onClick={onTicketEditStatusClicked} />
                    }
                  />
                )}
              </PermissionCheck>
            </div>
            <div className='ticket-info'>
              <SysTicketInfoPanel ticket={ticket} />
            </div>
          </div>
        </div>
      </motion.div>

      <Transition visible={deleteTicketErrorPopup} animation='scale' duration={1000}>
        <Modal size='small' open={deleteTicketErrorPopup}>
          <Modal.Header>لم تتم العملية بنجاح</Modal.Header>
          <Modal.Content>
            <div className='delete-ticket-errors-modal'>
              <p>لا يمكن {editStatusModalAttr.visible ? `اغلاق` : `حذف`} التذكرة للاسباب التالية:</p>
              {deleteErrors.map((str, index) => (
                <p className='red-text' key={index}>
                  <Icon name='circle' size='tiny' />
                  {str}
                </p>
              ))}
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color='grey'
              onClick={() => {
                setDeleteTicketErrorPopup(false);
                setEditStatusModalAttr({
                  visible: false,
                  loading: false,
                  position: { x: 0, y: 0 },
                });
              }}
            >
              موافق
            </Button>
          </Modal.Actions>
        </Modal>
      </Transition>

      <Transition visible={deleteTicketConfirmPopup} animation='scale' duration={1000}>
        <Modal size='small' open={deleteTicketConfirmPopup}>
          <Modal.Header>تأكيد الحذف</Modal.Header>
          <Modal.Content>
            <p>هل انت متأكد من حذف التذكرة؟</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color='red' onClick={() => deleteTicketHandler()}>
              نعم
            </Button>
            <Button color='green' onClick={() => setDeleteTicketConfirmPopup(false)}>
              لا
            </Button>
          </Modal.Actions>
        </Modal>
      </Transition>

      <Transition visible={deleteSuccess} animation='scale' duration={1000}>
        <Modal size='tiny' open={deleteSuccess}>
          <Modal.Header>تمت العملية بنجاح</Modal.Header>
          <Modal.Content>
            <p>لقد تم حذف التذكرة بنجاح.</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color='green'
              onClick={() => {
                setDeleteSuccess(false);
                setDeleteTicketConfirmPopup(false);
                onDelete();
              }}
            >
              موافق
            </Button>
          </Modal.Actions>
        </Modal>
      </Transition>
    </>
  );
};
