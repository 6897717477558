import store from '../redux/store/store';

let userPermissions = store.getState().user.user.p;
store.subscribe(() => {
  userPermissions = store.getState().user.user.p;
});

const permissions = {
  VIEW_DASHBOARD: 4,
  VIEW_MAINTENANCE_TICKETS: 5,
  VIEW_MAINTENANCE_REPORTS: 6,
  EDIT_MAINTENANCE_REPORTS: 7,
  EDIT_MAINTENANCE_TICKETS: 8,
  DELETE_MAINTENANCE_REPORT: 9,
  DELETE_TICKET: 10,
  VIEW_USERS: 11,
  CREATE_USER: 12,
  ACTIVATE_NEW_USER: 13,
  EDIT_USER: 14,
  DELETE_USER: 15,
  EDIT_TICKET_STATUS: 16,
  ADD_TICKET_NOTES: 17,
  CREATE_REPORT_FROM_TICKET: 18,
  CREATE_REPORT_WITHOUT_TICKET: 19,
  CREATE_TRACKING_VEHICLE_TICKET: 20,
  CREATE_SYSTEM_TICKET: 21,
  VIEW_CLIENTS: 22,
  EDIT_CLIENT: 23,
  DELETE_CLIENT: 24,
  CREATE_VEHICLE: 25,
  EDIT_VEHICLE: 26,
  DELETE_VEHICLE: 27,
  DISASSOCIATE_TRACKING_VEHICLE: 28,
  CREATE_TRACKING_UNIT: 29,
  EDIT_TRACKING_UNIT: 30,
  DELETE_TRACKING_UNIT: 31,
  CREATE_ROLE: 32,
  EDIT_ROLE: 33,
  DELETE_ROLE: 34,
  CHANGE_USER_ROLE: 35,
  CHANGE_USER_PASSWORD: 36,
  CREATE_LOOKUP: 37,
  EDIT_LOOKUP: 38,
  DELETE_LOOKUP: 39,
  VIEW_ALL_TRACKING_VEHICLES: 40,
  VIEW_ALL_MAINTENANCE_TICKETS: 41,
  VIEW_ALL_MAINTENANCE_REPORTS: 42,
  VIEW_ALL_USERS: 43,
  VIEW_ALL_CLIENTS: 44,
  EDIT_TICKET_STATUS_AFTER_CLOSING: 45,
  ASSOCIATE_TRACKING_VEHICLE: 46,
  CREATE_CLIENT: 47,
  VIEW_VEHICLES: 48,
  VIEW_TRACKING_UNITS: 49,
  VIEW_ROLES: 50,
  VIEW_LOOKUP: 51,
  VIEW_ALL_VEHICLES: 52,
  VIEW_ALL_TRACKING_UNITS: 53,
  VIEW_SLA: 85,
};

/**
 * @param {number} perm Permission to validate against
 * @param {object} obj (object|{}) to return instead of true|false
 * @returns {any} (object if provided or True) if all provided permissions are satisfied
 */
const hasPermission = (perm, obj = null) => {
  return userPermissions.has(perm) ? (Boolean(obj) ? obj : true) : Boolean(obj) ? {} : false;
};

/**
 * @param {number[]} perms Permissions to validate against
 * @param {object} obj (object|{}) to return instead of true|false
 * @returns {any} (object if provided or True) if any provided permission is satisfied
 */
const hasPermissions = (perms, obj = null) => {
  for (let i = 0; i < perms.length; i++) {
    if (userPermissions.has(perms[i])) {
      if (obj) return obj;
      return true;
    }
  }
  if (obj) return {};
  return false;
};

export { hasPermission, hasPermissions };
export default permissions;
