import React, { useState, useEffect } from 'react';
import { Form, Message, Button, Icon, Responsive, Image, Tab } from 'semantic-ui-react';
import { customersApi } from './../../../api/api';
import PageCardContainer from '../../Layout/PageCardContainer';
import { useHistory, useLocation } from 'react-router-dom';
import Webcam from 'react-webcam';

export default function EditUserForm() {
  const router = useHistory();
  const location = useLocation();
  const { id } = location.state;
  const [cameraOption, setCameraOption] = useState(false);

  const [formData, setFormData] = useState({
    fullName: '',
    shortName: '',
    website: '',
    contactName: '',
    contactPhone: '',
    contactEmail: '',
    image: '',
  });

  const [validationErrors, setValidationErrors] = useState({
    shortName: null,
    fullName: null,
    contactName: null,
    contactPhone: null,
    contactEmail: null,
    image: null,
  });

  const [touched, setTouched] = useState({
    shortName: false,
    fullName: false,
    contactName: false,
    contactPhone: false,
    contactEmail: false,
    image: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      //get data for editing
      const res = await customersApi.getOneCustomer(id);

      setFormData(res.data);
    };

    fetchData();
  }, []);

  const validation = () => {
    const errors = {
      shortName: null,
      fullName: null,
      contactName: null,
      contactPhone: null,
      contactEmail: null,
    };

    //FULLNAME VALIDATION
    if (!formData.fullName) {
      errors.fullName = 'الرجاء ادخال الاسم الكامل';
    } else if (formData.fullName.length < 3) {
      errors.fullName = 'الاسم الكامل يجب ان يكون 3 احرف على الاقل';
    }

    //Not all customers have websites, so no website validation

    //SHORTNAME VALIDATION
    if (!formData.shortName) {
      errors.shortName = 'الرجاء ادخال الاسم المختصر';
    }

    //CONTACT NAME VALIDATION
    if (!formData.contactName) {
      errors.contactName = 'الرجاء ادخال اسم التواصل';
    }

    //CONTACT EMAIL VALIDATION
    if (!formData.contactEmail) {
      errors.contactEmail = 'الرجاء ادخال الايميل';
    } else if (!formData.contactEmail.includes('@') || /[\u0621-\u064A\s]+/.test(formData.contactEmail)) {
      errors.contactEmail = 'الرجاء ادخال ايميل صحيح';
    }

    //CONTACT PHONE VALIDATION
    if (!formData.contactPhone) {
      errors.contactPhone = 'الرجاء ادخال رقم الهاتف';
    } else if (
      formData.contactPhone.length < 7 ||
      formData.contactPhone.length > 15 ||
      !/^[0-9]+$/.test(formData.contactPhone)
    ) {
      errors.contactPhone = 'الرجاء ادخال رقم هاتف صحيح';
    }

    setValidationErrors(errors);

    if (
      validationErrors.shortName ||
      validationErrors.fullName ||
      validationErrors.contactName ||
      validationErrors.contactPhone ||
      validationErrors.contactEmail ||
      validationErrors.image
    )
      return false;
    else return true;
  };

  useEffect(() => {
    validation();
  }, [formData]);

  const onChangeHandler = (e) => {
    setTouched({
      ...touched,
      [e.target.name]: true,
    });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const fileChangeHandler = (e) => {
    const fileUpload = document.getElementById('fileUpload');
    if (fileUpload.files.length) {
      const extension = fileUpload.value.split('.').pop().toLowerCase();
      if (extension === 'png' || extension === 'jpg' || extension === 'jpeg') {
        setErrors([]);
        const uploadedImage = fileUpload.files[0];
        const reader = new FileReader();
        reader.onload = function (upload) {
          setFormData({
            ...formData,
            image: `data:image/jpeg;base64, ${btoa(upload.target.result)}`,
          });
        };
        reader.readAsBinaryString(uploadedImage);
      } else {
        setErrors([
          {
            key: 1000,
            message: 'الرجاء اختيار صورة بامتداد png او jpg او jpeg',
          },
        ]);
      }
    }
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    setTouched({
      shortName: true,
      fullName: true,
      contactName: true,
      contactPhone: true,
      contactEmail: true,
      image: true,
    });

    if (validation()) {
      try {
        await customersApi.editCustomer(id, formData);
        router.goBack();
      } catch (e) {
        console.log('Error editing customer...', e);
        setIsSubmitting(false);
        setErrors({ key: 1, message: 'خطأ اثناء الحفظ' });
      }
    } else {
      setIsSubmitting(false);
    }
  };

  // capture camera image
  const webcamRef = React.useRef(null);

  const showCameraHandler = (e) => {
    e.preventDefault();
    setCameraOption(!cameraOption);
  };

  const cameraChangeHandler = (e) => {
    e.preventDefault();
    const imageSrc = webcamRef.current.getScreenshot();
    setFormData({ ...formData, image: imageSrc });
  };

  const panes = [
    {
      menuItem: 'صورة من الجهاز',
      render: () => <Tab.Pane>{imageFromDevice()}</Tab.Pane>,
    },
    {
      menuItem: 'صورة من الكاميرا',
      render: () => <Tab.Pane>{imageFromCamera()}</Tab.Pane>,
    },
  ];

  const imageFromCamera = () => {
    return (
      <>
        <Button fluid onClick={showCameraHandler}>
          تصوير بإستخدام كاميرا الجهاز
        </Button>

        {cameraOption && (
          <div className='camera-div'>
            <Webcam audio={false} ref={webcamRef} screenshotFormat='image/jpeg' className='video-div' />
            <Button fluid onClick={cameraChangeHandler}>
              التقاط صورة
            </Button>
          </div>
        )}
      </>
    );
  };

  const imageFromDevice = () => {
    return (
      <>
        <Form.Field>
          <label>رفع صورة من الجهاز</label>
          <Form.Input
            type='file'
            id='fileUpload'
            onChange={fileChangeHandler}
            error={touched.image && validationErrors.image}
          />
        </Form.Field>
      </>
    );
  };

  return (
    <PageCardContainer>
      <div style={{ margin: '1rem' }} className='form-margin'>
        <h2 style={{ marginBottom: '2rem' }}>تعديل معلومات العميل</h2>
        <Form error style={{ margin: '1rem' }} onSubmit={onSubmitHandler}>
          <Form.Group widths={2}>
            <Form.Field>
              <label>الاسم الكامل</label>
              <Form.Input
                icon='user'
                value={formData['fullName']}
                name='fullName'
                onChange={onChangeHandler}
                error={touched.fullName && validationErrors.fullName}
              />
            </Form.Field>

            <Form.Field>
              <label>الاسم المختصر</label>
              <Form.Input
                icon='user circle'
                value={formData['shortName']}
                name='shortName'
                onChange={onChangeHandler}
                error={touched.shortName && validationErrors.shortName}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <label>الموقع الاكتروني</label>
              <Form.Input icon='globe' value={formData['website']} name='website' onChange={onChangeHandler} />
            </Form.Field>

            <Form.Field>
              <label>الايميل</label>
              <Form.Input
                icon='mail'
                type='contactEmail'
                value={formData['contactEmail']}
                name='contactEmail'
                onChange={onChangeHandler}
                error={touched.contactEmail && validationErrors.contactEmail}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <label>الهاتف</label>
              <Form.Input
                icon='phone'
                value={formData['contactPhone']}
                name='contactPhone'
                onChange={onChangeHandler}
                error={touched.contactPhone && validationErrors.contactPhone}
              />
            </Form.Field>

            <Form.Field>
              <label>اسم التواصل</label>
              <Form.Input
                icon='pencil'
                type='contactName'
                value={formData['contactName']}
                name='contactName'
                onChange={onChangeHandler}
                error={touched.contactName && validationErrors.contactName}
              />
            </Form.Field>
          </Form.Group>

          <h5>صورة العميل</h5>
          <Tab panes={panes} />
          {formData.image && (
            <>
              <div className='image-preview-header'>عرض صورة العميل الحالية</div>
              <div className='image-preview'>
                <div className='image-cropper'>
                  <Image src={formData.image} className='img' centered />
                </div>
              </div>
            </>
          )}

          <Message error key={errors.key} content={errors.message} />

          <Responsive minWidth={768}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                type='submit'
                loading={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginLeft: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
          <Responsive maxWidth={767}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                fluid
                type='submit'
                loading={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginBottom: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                fluid
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
        </Form>
      </div>
    </PageCardContainer>
  );
}
