import React, { useEffect, useState } from 'react';
import { customersApi } from '../../../api/api';
import { useLocation } from 'react-router-dom';
import PageCardContainer from '../../Layout/PageCardContainer';
import { Icon, Image, Table, Loader } from 'semantic-ui-react';
import userImage from './../../../assets/images/user-icon.png';
import './../Users/Users.scss';

export default function ViewUserDetails() {
  const location = useLocation();
  const { id } = location.state;

  const [customerData, setCustomerData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await customersApi.getOneCustomer(id);
      setCustomerData(res.data);
      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <div>
      <PageCardContainer>
        {loading ? (
          <Loader active className='loader-margin-top' size='large' inline='centered' />
        ) : (
          <div className='user-details'>
            <div className='user-card'>
              <div className='header-img'>
                <div className='image-cropper'>
                  <Image src={customerData.image ? customerData.image : userImage} className='img' centered />
                </div>
              </div>

              <div className='contents'>
                <h2>{customerData.fullName}</h2>
                <hr />

                <Table basic='very' collapsing unstackable>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        <span style={{ marginLeft: '1rem' }}>
                          <Icon name='user circle' /> الاسم المختصر:
                        </span>
                      </Table.Cell>
                      <Table.Cell>
                        <b>{customerData.shortName}</b>
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>
                        <span style={{ marginLeft: '1rem' }}>
                          <Icon name='globe' /> الموقع الالكتروني:
                        </span>
                      </Table.Cell>
                      <Table.Cell>
                        <b>{customerData.website}</b>
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>
                        <span style={{ marginLeft: '1rem' }}>
                          <Icon name='mail' /> الايميل:
                        </span>
                      </Table.Cell>
                      <Table.Cell>
                        <b>{customerData.contactEmail}</b>
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>
                        <span style={{ marginLeft: '1rem' }}>
                          <Icon name='phone' /> الهاتف:
                        </span>
                      </Table.Cell>
                      <Table.Cell>
                        <b>{customerData.contactPhone}</b>
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>
                        <span style={{ marginLeft: '1rem' }}>
                          <Icon name='phone' /> اسم التواصل:
                        </span>
                      </Table.Cell>
                      <Table.Cell>
                        <b>{customerData.contactName}</b>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </div>
          </div>
        )}
      </PageCardContainer>
    </div>
  );
}
