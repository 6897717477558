import React, { useState, useEffect } from 'react';
import { Responsive } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import qs from 'qs';
import { customerTicketsApi } from '../../api/api';
import { excelExport } from '../../helpers/excelExport';
import { ticketsActions } from '../../redux/actions/actionCreator';
import CustomerTickets from './CustomerTickets/CustomerTickets';
import CustomerTicketsMobile from './CustomerTicketsMobile/CustomerTicketsMobile';

// TODO: Find a way to keep these lookups consistant across deployments
const statusNames = {
  18: { name: 'مفتوحة' }, // Open
  19: { name: 'قيد التنفيذ' }, // In Progress
  20: { name: 'مغلقة' }, // Closed
};

const CustomerTicketsContainer = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const dispatch = useDispatch();
  const selectedTicket = useSelector((state) => state.tickets.selectedTicket);

  const fetchData = async (filters) => {
    // TODO: Handle multiple calls (one from the reload effect + one from the onSearch prop sent to the search component)
    try {
      setLoading(true);
      for (const key in filters) if (!filters[key]) delete filters[key];
      const queryString = qs.stringify(filters, { addQueryPrefix: true });
      const res = await customerTicketsApi.getAll(queryString);
      if (res.data.data) {
        setData(res.data.data);
      }
      setLoading(false);
    } catch (error) {
      // TODO: handle errors
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData({ status: 18 });
  }, [reload]);

  const onTicketSelected = (ticket) => {
    dispatch(ticketsActions.setSelectedTicket(ticket));
  };

  const onTicketStatusChanged = (statusId) => {
    const updatedTickets = [...data];
    const ticket = updatedTickets.find((ticket) => ticket.id === selectedTicket.id);
    ticket.status = statusId;
    ticket.statusName = statusNames[statusId].name;
    setData(updatedTickets);
  };

  const handleDeleteTicket = () => {
    setReload(!reload);
    dispatch(ticketsActions.clearSelectedTicket());
  };

  const exportFileHandler = () => {
    let excelData = [];
    excelData = data.map((item) => ({
      'Ticket Id': item.id,
      'Customer Name': item.fullName,
      'Ticket Type': item.typeName,
      'Created At': moment(item.createdAt).format('YYYY-MM-DD, hh:mm A'),
      'Created By': item.createdBy ? item.createdBy : '-',
      'Closed At': item.closedAt ? moment(item.closedAt).format('YYYY-MM-DD, hh:mm A') : '-',
      'Closed By': item.closedBy ? item.closedBy : '-',
      'Response Date': item.responseAt ? moment(item.responseAt).format('YYYY-MM-DD, hh:mm A') : '-',
      'Response Duration':
        item.responseAt && item.createdAt
          ? moment.utc(moment(item.responseAt).diff(moment(item.createdAt))).format('D,HH:mm:ss')
          : '-',
      'Working Duration':
        item.responseAt && item.closedAt
          ? moment.utc(moment(item.closedAt).diff(moment(item.responseAt))).format('D,HH:mm:ss')
          : '-',
    }));
    excelExport(excelData, 'تذاكر_الصيانة');
  };

  return (
    <>
      <Responsive maxWidth={767} style={{ height: '100%' }}>
        <CustomerTicketsMobile
          data={data}
          fetchData={fetchData}
          loading={loading}
          onTicketSelected={onTicketSelected}
          onTicketStatusChanged={onTicketStatusChanged}
          exportFileHandler={exportFileHandler}
          selectedTicket={selectedTicket}
          handleDeleteTicket={handleDeleteTicket}
        />
      </Responsive>
      <Responsive minWidth={768} style={{ height: '100%' }}>
        <CustomerTickets
          data={data}
          fetchData={fetchData}
          loading={loading}
          onTicketSelected={onTicketSelected}
          onTicketStatusChanged={onTicketStatusChanged}
          exportFileHandler={exportFileHandler}
          selectedTicket={selectedTicket}
          handleDeleteTicket={handleDeleteTicket}
        />
      </Responsive>
    </>
  );
};

export default CustomerTicketsContainer;
