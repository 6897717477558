import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Icon, Menu, Image, Responsive, Sidebar, Popup } from 'semantic-ui-react';
import logo from './../../assets/images/logo.png';
import UserBadge from './UserBadge';

export default function SidebarContent(props) {
  const location = useLocation();
  const router = useHistory();

  const [activeItem, setActiveItem] = useState(location.pathname.split('/')[1] || 'home');
  const [visibleNav, setVisibleNav] = useState(false);

  const handleItemClick = (e, { name }) => {
    if (name !== activeItem) {
      setActiveItem(name);
    }
    router.push(`/${name}`);
    setVisibleNav(false);
  };

  const verticalMenu = () => {
    return (
      <>
        <Menu icon='labeled' vertical className='borderless'>
          <Menu.Header className='menu-header'>
            <Image src={logo} alt='logo' size='tiny' />
          </Menu.Header>
          <Menu.Item name='home' active={activeItem === 'home'} onClick={handleItemClick}>
            <Icon name='chart pie' />
            الرئيسية
          </Menu.Item>
          <Menu.Item name='tickets-menu' active={activeItem === 'tickets-menu'} onClick={handleItemClick}>
            <Icon name='ticket' />
            التذاكر
          </Menu.Item>
          <Menu.Item name='settings' active={activeItem === 'settings'} onClick={handleItemClick}>
            <Icon name='cogs' />
            الإعدادات
          </Menu.Item>
        </Menu>
      </>
    );
  };

  const verticalMenuSlim = () => {
    return (
      <>
        <div className='vertical-slim'>
          <Menu icon='labeled' vertical className='borderless'>
            <Menu.Header className='menu-header'>
              <Image src={logo} alt='logo' size='tiny' />
            </Menu.Header>
            <Popup
              content='الصفحة الرئيسية'
              trigger={
                <Menu.Item name='home' active={activeItem === 'home'} onClick={handleItemClick}>
                  <Icon name='chart pie' />
                </Menu.Item>
              }
              position='left center'
            />
            <Popup
              content='التذاكر'
              trigger={
                <Menu.Item name='tickets-menu' active={activeItem === 'tickets-menu'} onClick={handleItemClick}>
                  <Icon name='ticket' />
                </Menu.Item>
              }
              position='left center'
            />
            <Popup
              content='الإعدادات'
              trigger={
                <Menu.Item name='settings' active={activeItem === 'settings'} onClick={handleItemClick}>
                  <Icon name='cogs' />
                </Menu.Item>
              }
              position='left center'
            />
          </Menu>
        </div>
      </>
    );
  };

  const topMenu = () => {
    return (
      <Menu inverted style={{ height: '60px', marginBottom: '0' }}>
        <Menu.Item position='left' onClick={() => setVisibleNav(!visibleNav)}>
          <Icon name='sidebar' size='large' />
        </Menu.Item>
        <Menu.Item>
          <UserBadge />
        </Menu.Item>
        <Menu.Header className='menu-header'>
          <Image src={logo} alt='logo' size='tiny' style={{ height: '60px', width: '60px' }} />
        </Menu.Header>
      </Menu>
    );
  };

  const horizontalMenu = (props) => {
    return (
      <Sidebar.Pushable>
        <Sidebar as={Menu} animation='overlay' icon='labeled' inverted vertical visible={visibleNav} direction='right'>
          <div className='vertical-menu'>{verticalMenu()}</div>
        </Sidebar>

        <Sidebar.Pusher
          dimmed={visibleNav}
          onClick={() => {
            if (visibleNav) setVisibleNav(false);
          }}
          style={{ minHeight: '100vh' }}
        >
          <div className='top-menu'>{topMenu()}</div>
          {props}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  };

  return (
    <>
      <Responsive maxWidth={767}>{horizontalMenu(props.children)}</Responsive>
      <Responsive minWidth={768}>{verticalMenuSlim()}</Responsive>
    </>
  );
}
