import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import LookupsGroups from './components/Settings/Lookups/LookupsGroups';
import LookupsDetails from './components/Settings/Lookups/LookupsDetails';
import Roles from './components/Settings/Roles/Roles';
import SidebarPage from './components/Layout/SidebarPage';
import SettingsList from './components/Settings/SettingsList/SettingsList';
import AddLookupForm from './components/Settings/Lookups/AddLookupForm';
import EditLookupForm from './components/Settings/Lookups/EditLookupForm';
import AddRoleForm from './components/Settings/Roles/AddRoleForm';
import EditRoleForm from './components/Settings/Roles/EditRoleForm';
import ViewRoleDetails from './components/Settings/Roles/ViewRoleDetails';
import Login from './components/UserAuth/Login';
import Signup from './components/UserAuth/Signup';
import Users from './components/Settings/Users/Users';
import AddUserForm from './components/Settings/Users/AddUserForm';
import EditUserForm from './components/Settings/Users/EditUserForm';
import ViewUserDetails from './components/Settings/Users/ViewUserDetails';
import EditPasswordForm from './components/Settings/Users/EditPasswordForm';
import Customers from './components/Settings/Customers/Customers';
import AddCustomer from './components/Settings/Customers/AddCustomer';
import EditCustomer from './components/Settings/Customers/EditCustomer';
import ViewCustomerDetails from './components/Settings/Customers/ViewCustomerDetails';
import AddMaintenanceForm from './components/Tickets/MaintenanceForm/AddMaintenanceForm';
import EditMaintenanceForm from './components/Tickets/MaintenanceForm/EditMaintenanceForm';
import Vehicles from './components/Settings/Vehicles/Vehicles';
import AddVehicle from './components/Settings/Vehicles/AddVehicle';
import EditVehicle from './components/Settings/Vehicles/EditVehicle';
import TrackingUnits from './components/Settings/TrackingUnits/TrackingUnits';
import AddTrackingUnit from './components/Settings/TrackingUnits/AddTrackingUnit';
import EditTrackingUnit from './components/Settings/TrackingUnits/EditTrackingUnit';
import ViewTrackingUnitDetails from './components/Settings/TrackingUnits/ViewTrackingUnitDetails';
import TicketsMenu from './components/Tickets/TicketsMenu';
import SysTicketAddForm from './components/Tickets/SystemMaintenanceTickets/SysTicketAddForm/SysTicketAddForm';
import MaintenanceForm from './components/Tickets/MaintenanceForm/MaintenanceForm';
import ViewMaintenanceFormDetails from './components/Tickets/MaintenanceForm/ViewMaintenanceFormDetails';
import PrintMaintenanceForm from './components/Tickets/MaintenanceForm/PrintMaintenanceForm';
import AddMaintenanceTicket from './components/Tickets/SystemMaintenanceTickets/MaintenanceTicket/AddMaintenanceTicket';
import EditMaintenanceTicket from './components/Tickets/SystemMaintenanceTickets/MaintenanceTicket/EditMaintenanceTicket';
import NotFound from './components/NotFound/NotFound';
import PermissionCheck from './components/Reusable/PermissionCheck';
import p from './helpers/permissions';
import CustomerTicketsContainer from './components/Tickets/CustomerTicketsContainer';
import MaintenanceReport from './components/Tickets/MaintenanceReport';
import MonthlySystemTicketsReport from './components/Tickets/MonthlySystemTicketsReport';
import Dashboard from './components/Dashboard/Dashboard';
import { loadState } from './api/sessionStorage';
import { useSelector } from 'react-redux';

function App() {
  const reduxState = useSelector((x) => x.user).user;
  return (
    <Switch>
      <Route exact path='/login' component={Login} />
      <Route exact path='/signup' component={Signup} />
      {!(reduxState.isLoggedIn || sessionStorage.getItem('user')) && window.location.pathname !== '/login' && (
        <Redirect to='/login' />
      )}
      <Route exact path='/maintenanceForm/print' component={PrintMaintenanceForm} />

      <SidebarPage>
        <Switch>
          <Route exact path={['/', '/home']}>
            <Dashboard />
          </Route>
          {/* <Route exact path={'/'}>
            {!loadState().isLoggedIn ? <Redirect to='/login' /> : <Dashboard />}
          </Route>*/}

          <Route exact path={'/settings'} component={SettingsList} />
          <Route exact path='/lookupsGroups'>
            <PermissionCheck permissions={[p.VIEW_LOOKUP, p.EDIT_LOOKUP, p.DELETE_LOOKUP, p.CREATE_LOOKUP]}>
              <LookupsGroups />
            </PermissionCheck>
          </Route>
          <Route exact path='/lookupsDetails'>
            <PermissionCheck permissions={p.VIEW_LOOKUP}>
              <LookupsDetails />
            </PermissionCheck>
          </Route>
          <Route exact path='/lookups/add'>
            <PermissionCheck permissions={p.CREATE_LOOKUP}>
              <AddLookupForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/lookups/edit'>
            <PermissionCheck permissions={p.EDIT_LOOKUP}>
              <EditLookupForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/roles'>
            <PermissionCheck permissions={[p.VIEW_ROLES, p.CREATE_ROLE, p.EDIT_ROLE, p.DELETE_ROLE]}>
              <Roles />
            </PermissionCheck>
          </Route>
          <Route exact path='/roles/view'>
            <PermissionCheck permissions={p.VIEW_ROLES}>
              <ViewRoleDetails />
            </PermissionCheck>
          </Route>
          <Route exact path='/roles/add'>
            <PermissionCheck permissions={p.CREATE_ROLE}>
              <AddRoleForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/roles/edit'>
            <PermissionCheck permissions={p.EDIT_ROLE}>
              <EditRoleForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/users'>
            <PermissionCheck
              permissions={[
                p.VIEW_USERS,
                p.VIEW_ALL_USERS,
                p.CHANGE_USER_PASSWORD,
                p.CREATE_USER,
                p.EDIT_USER,
                p.DELETE_USER,
              ]}
            >
              <Users />
            </PermissionCheck>
          </Route>
          <Route exact path='/users/add'>
            <PermissionCheck permissions={p.CREATE_USER}>
              <AddUserForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/users/edit'>
            <PermissionCheck permissions={p.EDIT_USER}>
              <EditUserForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/users/editPassword'>
            <PermissionCheck permissions={p.CHANGE_USER_PASSWORD}>
              <EditPasswordForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/users/view'>
            <PermissionCheck permissions={[p.VIEW_USERS, p.VIEW_ALL_USERS]}>
              <ViewUserDetails />
            </PermissionCheck>
          </Route>
          <Route exact path='/customers'>
            <PermissionCheck
              permissions={[p.CREATE_CLIENT, p.VIEW_ALL_CLIENTS, p.VIEW_CLIENTS, p.DELETE_CLIENT, p.EDIT_CLIENT]}
            >
              <Customers />
            </PermissionCheck>
          </Route>
          <Route exact path='/customers/add'>
            <PermissionCheck permissions={p.CREATE_CLIENT}>
              <AddCustomer />
            </PermissionCheck>
          </Route>
          <Route exact path='/customers/edit'>
            <PermissionCheck permissions={p.EDIT_CLIENT}>
              <EditCustomer />
            </PermissionCheck>
          </Route>
          <Route exact path='/customers/view'>
            <PermissionCheck permissions={[p.VIEW_ALL_CLIENTS, p.VIEW_CLIENTS]}>
              <ViewCustomerDetails />
            </PermissionCheck>
          </Route>
          <Route exact path='/vehicles'>
            <PermissionCheck
              permissions={[p.VIEW_VEHICLES, p.VIEW_ALL_VEHICLES, p.EDIT_VEHICLE, p.DELETE_VEHICLE, p.CREATE_VEHICLE]}
            >
              <Vehicles />
            </PermissionCheck>
          </Route>
          <Route exact path='/vehicles/add'>
            <PermissionCheck permissions={p.CREATE_VEHICLE}>
              <AddVehicle />
            </PermissionCheck>
          </Route>
          <Route exact path='/vehicles/edit'>
            <PermissionCheck permissions={p.EDIT_VEHICLE}>
              <EditVehicle />
            </PermissionCheck>
          </Route>
          <Route exact path='/trackingUnits'>
            <PermissionCheck
              permissions={[
                p.VIEW_TRACKING_UNITS,
                p.VIEW_ALL_TRACKING_UNITS,
                p.CREATE_TRACKING_UNIT,
                p.EDIT_TRACKING_UNIT,
              ]}
            >
              <TrackingUnits />
            </PermissionCheck>
          </Route>
          <Route exact path='/trackingUnits/add'>
            <PermissionCheck permissions={p.CREATE_TRACKING_UNIT}>
              <AddTrackingUnit />
            </PermissionCheck>
          </Route>
          <Route exact path='/trackingUnits/edit'>
            <PermissionCheck permissions={p.EDIT_TRACKING_UNIT}>
              <EditTrackingUnit />
            </PermissionCheck>
          </Route>
          <Route exact path='/trackingUnits/view'>
            <PermissionCheck permissions={[p.VIEW_TRACKING_UNITS, p.VIEW_ALL_TRACKING_UNITS]}>
              <ViewTrackingUnitDetails />
            </PermissionCheck>
          </Route>
          <Route exact path='/tickets-menu'>
            <TicketsMenu />
          </Route>
          <Route exact path='/sys-tickets'>
            <PermissionCheck
              permissions={[
                p.VIEW_MAINTENANCE_REPORTS,
                p.VIEW_MAINTENANCE_TICKETS,
                p.VIEW_ALL_MAINTENANCE_REPORTS,
                p.VIEW_ALL_MAINTENANCE_TICKETS,
                p.CREATE_REPORT_FROM_TICKET,
                p.CREATE_SYSTEM_TICKET,
                p.EDIT_MAINTENANCE_TICKETS,
                p.CREATE_TRACKING_VEHICLE_TICKET,
                p.DELETE_TICKET,
              ]}
            >
              <CustomerTicketsContainer />
            </PermissionCheck>
          </Route>
          <Route exact path='/sys-tickets/add'>
            <PermissionCheck permissions={[p.CREATE_SYSTEM_TICKET, p.EDIT_MAINTENANCE_TICKETS]}>
              <SysTicketAddForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/maintenanceForm'>
            <PermissionCheck
              permissions={[
                p.VIEW_MAINTENANCE_REPORTS,
                p.CREATE_REPORT_WITHOUT_TICKET,
                p.DELETE_MAINTENANCE_REPORT,
                p.EDIT_MAINTENANCE_REPORTS,
                p.VIEW_ALL_MAINTENANCE_REPORTS,
              ]}
            >
              <MaintenanceForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/maintenanceForm/add'>
            <PermissionCheck permissions={[p.CREATE_REPORT_WITHOUT_TICKET, p.CREATE_REPORT_FROM_TICKET]}>
              <AddMaintenanceForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/maintenanceForm/edit'>
            <PermissionCheck permissions={p.EDIT_MAINTENANCE_REPORTS}>
              <EditMaintenanceForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/maintenanceForm/view'>
            <PermissionCheck permissions={[p.VIEW_ALL_MAINTENANCE_REPORTS, p.VIEW_MAINTENANCE_REPORTS]}>
              <ViewMaintenanceFormDetails />
            </PermissionCheck>
          </Route>
          <Route exact path='/maintenanceTicket/add'>
            <PermissionCheck permissions={p.CREATE_TRACKING_VEHICLE_TICKET}>
              <AddMaintenanceTicket />
            </PermissionCheck>
          </Route>
          <Route exact path='/maintenanceTicket/edit'>
            <PermissionCheck permissions={p.EDIT_MAINTENANCE_TICKETS}>
              <EditMaintenanceTicket />
            </PermissionCheck>
          </Route>
          <Route exact path='/sys-reports'>
            <PermissionCheck permissions={[p.VIEW_SLA]}>
              <MaintenanceReport />
            </PermissionCheck>
          </Route>
          <Route exact path='/monthly-sys-tickets-reports'>
            <PermissionCheck permissions={[p.VIEW_SLA]}>
              <MonthlySystemTicketsReport />
            </PermissionCheck>
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </SidebarPage>
    </Switch>
  );
}

export default App;
