import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Message, Button, Dropdown, Icon, Responsive, Tab, Image, Checkbox } from 'semantic-ui-react';
import { customersApi, lookupsApi, rolesApi, usersApi } from './../../../api/api';
import PageCardContainer from '../../Layout/PageCardContainer';
import Webcam from 'react-webcam';
import './UserForm.scss';

export default function AddUserForm() {
  const [formData, setFormData] = useState({
    userName: '',
    fullName: '',
    password: '',
    passwordConfirm: '',
    phone: '',
    email: '',
    jobTitle: null,
    role: null,
    image: '',
  });

  const [validationErrors, setValidationErrors] = useState({
    userName: null,
    fullName: null,
    password: null,
    passwordConfirm: null,
    phone: null,
    email: null,
    image: null,
  });

  const [touched, setTouched] = useState({
    userName: false,
    fullName: false,
    password: false,
    passwordConfirm: false,
    phone: false,
    email: false,
    image: false,
  });

  const [jobTitleOptions, setJobTitleOptions] = useState([]);
  const [rolesOptions, setRolesOptions] = useState([]);
  const [customerIdOptions, setCustomerIdOptions] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errors, setErrors] = useState([]);

  const router = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      //get lookups for jobtitles
      const jobTitles = await lookupsApi.getGroup(2);
      //loop over the data and store in an array of objects for select options
      const options1 = jobTitles.data.map((item) => ({
        key: item.id,
        text: item.name,
        value: item.id,
      }));
      setJobTitleOptions(options1);
      //get roles
      const roles = await rolesApi.getAllRoles();
      const options2 = roles.data.data.map((item) => ({
        key: item.id,
        text: item.name,
        value: item.id,
      }));
      setRolesOptions(options2);
      //get customers
      const { data: customers } = await customersApi.getCustomersDropDown();
      for (let result of customers) {
        result.isChecked = false;
      }
      setCustomerIdOptions(customers);

      setFormData({
        ...formData,
        jobTitle: options1[0].key,
        role: options2[0].key,
        customerIds: [],
      });
    };

    fetchData();
  }, []);

  const validation = () => {
    const errors = {
      userName: null,
      fullName: null,
      password: null,
      passwordConfirm: null,
      phone: null,
      email: null,
      image: null,
    };

    //USERNAME VALIDATION
    if (!formData.userName) {
      errors.userName = 'الرجاء ادخال اسم المستخدم';
    } else if (formData.userName.length < 3) {
      errors.userName = 'اسم المستخدم يجب ان يكون 3 احرف على الاقل';
    } else if (/[\u0621-\u064A\s]+/.test(formData.userName)) {
      errors.userName = 'اسم المستخدم يجب ان يحتوي على احرف انجليزية وارقام و رموز فقط';
    }

    //FULLNAME VALIDATION
    if (!formData.fullName) {
      errors.fullName = 'الرجاء ادخال الاسم الكامل';
    } else if (formData.fullName.length < 3) {
      errors.fullName = 'الاسم الكامل يجب ان يكون 3 احرف على الاقل';
    }

    //PASSWORD VALIDATION
    if (!formData.password) {
      errors.password = 'الرجاء ادخال كلمة السر';
    } else if (formData.password.length < 3) {
      errors.password = 'كلمة السر يجب ان تكون 3 احرف على الاقل';
    }

    //PASSWORD CONFIRMATION VALIDATION
    if (!formData.passwordConfirm) {
      errors.passwordConfirm = 'الرجاء ادخال تأكيد كلمة السر';
    } else if (formData.password.length < 3 || formData.password !== formData.passwordConfirm) {
      errors.passwordConfirm = 'كلمة السر غير متطابقة';
    }

    //EMAIL VALIDATION
    if (!formData.email) {
      errors.email = 'الرجاء ادخال الايميل';
    } else if (!formData.email.includes('@') || /[\u0621-\u064A\s]+/.test(formData.email)) {
      errors.email = 'الرجاء ادخال ايميل صحيح';
    }

    //PHONE VALIDATION
    if (!formData.phone) {
      errors.phone = 'الرجاء ادخال رقم الهاتف';
    } else if (formData.phone.length < 7 || formData.phone.length > 15 || !/^[0-9]+$/.test(formData.phone)) {
      errors.phone = 'الرجاء ادخال رقم هاتف صحيح';
    }

    setValidationErrors(errors);

    if (
      validationErrors.userName ||
      validationErrors.fullName ||
      validationErrors.password ||
      validationErrors.passwordConfirm ||
      validationErrors.phone ||
      validationErrors.email ||
      validationErrors.image
    )
      return false;
    else return true;
  };

  useEffect(() => {
    validation();
  }, [formData]);

  const onChangeHandler = (e) => {
    setTouched({
      ...touched,
      [e.target.name]: true,
    });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const jobSelectionChangeHandler = (e, data) => {
    setFormData({ ...formData, jobTitle: data.value });
  };

  const roleSelectionChangeHandler = (e, data) => {
    setFormData({ ...formData, role: data.value });
  };

  const fileChangeHandler = (e) => {
    const fileUpload = document.getElementById('fileUpload');
    if (fileUpload.files.length) {
      const extension = fileUpload.value.split('.').pop().toLowerCase();
      if (extension === 'png' || extension === 'jpg' || extension === 'jpeg') {
        setErrors([]);
        const uploadedImage = fileUpload.files[0];
        const reader = new FileReader();
        reader.onload = function (upload) {
          setFormData({ ...formData, image: `data:image/jpeg;base64, ${btoa(upload.target.result)}` });
        };
        reader.readAsBinaryString(uploadedImage);
      } else {
        setErrors([
          {
            key: 1000,
            message: 'الرجاء اختيار صورة بامتداد png او jpg او jpeg',
          },
        ]);
      }
    }
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let errorsList = [];

    setTouched({
      userName: true,
      fullName: true,
      password: true,
      passwordConfirm: true,
      phone: true,
      email: true,
    });

    if (!customerIdOptions.find((x) => x.isChecked === true)) {
      errorsList.push({ key: 0, message: 'يجب اختيار عميل واحد على الاقل' });
    } else {
      errorsList = [];
    }

    if (validation() && !errorsList.length) {
      // construct permissions list of ids
      let customerIds = [];
      for (let c of customerIdOptions) {
        if (c.isChecked) customerIds.push(c.id);
      }

      try {
        await usersApi.postUser({
          ...formData,
          customerIds: customerIds,
        });
        router.goBack();
      } catch (e) {
        console.log('Error posting user...', e);
        setIsSubmitting(false);
        if (e.response && e.response.status === 400 && e.response.data) {
          if (e.response.data.username) errorsList.push({ key: 0, message: 'اسم المستخدم مكرر' });
          if (e.response.data.phone) errorsList.push({ key: 1, message: 'رقم الهاتف مكرر' });
          if (e.response.data.email) errorsList.push({ key: 2, message: 'الايميل مكرر' });
          setErrors(errorsList);
        } else {
          console.log('Error posting user...', e);
          setErrors([{ key: 0, message: 'خطأ اثناء الحفظ' }]);
        }
      }
    } else {
      setErrors(errorsList);
      setIsSubmitting(false);
    }
  };

  // capture camera image
  const webcamRef = React.useRef(null);

  const cameraChangeHandler = (e) => {
    e.preventDefault();
    const imageSrc = webcamRef.current.getScreenshot();
    setFormData({ ...formData, image: imageSrc });
  };

  const panes = [
    {
      menuItem: 'صورة من الجهاز',
      render: () => <Tab.Pane>{imageFromDevice()}</Tab.Pane>,
    },
    {
      menuItem: 'صورة من الكاميرا',
      render: () => <Tab.Pane>{imageFromCamera()}</Tab.Pane>,
    },
  ];

  const imageFromDevice = () => {
    return (
      <>
        <Form.Field>
          <label>رفع صورة من الجهاز</label>
          <Form.Input
            type='file'
            id='fileUpload'
            onChange={fileChangeHandler}
            error={touched.image && validationErrors.image}
          />
        </Form.Field>
      </>
    );
  };

  const imageFromCamera = () => {
    return (
      <>
        {!Boolean(formData.image) ? (
          <div className='camera-div'>
            <Webcam audio={false} ref={webcamRef} screenshotFormat='image/jpeg' className='video-div' />
            <Button fluid onClick={cameraChangeHandler}>
              التقاط صورة
            </Button>
          </div>
        ) : (
          <Button fluid onClick={() => setFormData({ ...formData, image: null })}>
            التقاط صورة جديدة
          </Button>
        )}
      </>
    );
  };

  const checkboxChangeHandler = (id) => {
    let newState = [...customerIdOptions];
    let customer = newState.find((x) => x.id === id);
    customer.isChecked = !customer.isChecked;
    setCustomerIdOptions(newState);
  };

  return (
    <PageCardContainer>
      <div style={{ margin: '1rem' }} className='form-margin userForm'>
        <h2 style={{ marginBottom: '2rem' }}>تسجيل حساب جديد</h2>
        <Form error style={{ margin: '1rem' }} onSubmit={onSubmitHandler}>
          <Form.Group widths={2}>
            <Form.Field>
              <label>اسم المستخدم</label>
              <Form.Input
                icon='user'
                value={formData['userName']}
                name='userName'
                onChange={onChangeHandler}
                error={touched.userName && validationErrors.userName}
              />
            </Form.Field>

            <Form.Field>
              <label>الاسم الكامل</label>
              <Form.Input
                icon='user circle'
                value={formData['fullName']}
                name='fullName'
                onChange={onChangeHandler}
                error={touched.fullName && validationErrors.fullName}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <label>كلمة السر</label>
              <Form.Input
                icon='lock'
                type='password'
                value={formData['password']}
                name='password'
                onChange={onChangeHandler}
                error={touched.password && validationErrors.password}
              />
            </Form.Field>

            <Form.Field>
              <label>تأكيد كلمة السر</label>
              <Form.Input
                icon='lock'
                type='password'
                value={formData['passwordConfirm']}
                name='passwordConfirm'
                onChange={onChangeHandler}
                error={touched.passwordConfirm && validationErrors.passwordConfirm}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <label>الهاتف</label>
              <Form.Input
                icon='phone'
                value={formData['phone']}
                name='phone'
                onChange={onChangeHandler}
                error={touched.phone && validationErrors.phone}
              />
            </Form.Field>

            <Form.Field>
              <label>الايميل</label>
              <Form.Input
                icon='mail'
                type='email'
                value={formData['email']}
                name='email'
                onChange={onChangeHandler}
                error={touched.email && validationErrors.email}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <label>المسمى الوظيفي</label>
              <Dropdown
                placeholder='اختر المسمى الوظيفي'
                selection
                fluid
                search
                value={formData['jobTitle']}
                options={jobTitleOptions}
                onChange={jobSelectionChangeHandler}
                className='table-dropdown'
                style={{ marginLeft: '0.5rem' }}
              />
            </Form.Field>

            <Form.Field>
              <label>الصلاحيات</label>
              <Dropdown
                placeholder='اختر الصلاحية'
                fluid
                selection
                search
                value={formData['role']}
                options={rolesOptions}
                onChange={roleSelectionChangeHandler}
                className='table-dropdown'
                style={{ marginLeft: '0.5rem' }}
              />
            </Form.Field>
          </Form.Group>

          <Form.Field>
            <label>العملاء الفرعيون</label>
            <div className='checkbox-div'>
              {customerIdOptions.map((customer) => {
                return (
                  <div key={customer.id} className='one-checkbox'>
                    <Checkbox
                      label={customer.name}
                      name={customer.name}
                      checked={customer.isChecked}
                      onClick={() => checkboxChangeHandler(customer.id)}
                    />
                  </div>
                );
              })}
            </div>
          </Form.Field>

          <h5>صورة المستخدم</h5>
          <Tab panes={panes} />
          {formData.image && (
            <>
              <div className='image-preview-header'>عرض صورة المستخدم الحالية</div>
              <div className='image-preview'>
                <div className='image-cropper'>
                  <Image src={formData.image} className='img' centered />
                </div>
              </div>
            </>
          )}

          {errors.map((item) => {
            return <Message error key={item.key} content={item.message} />;
          })}

          <Responsive minWidth={768}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginLeft: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
          <Responsive maxWidth={767}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                fluid
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginBottom: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                fluid
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
        </Form>
      </div>
    </PageCardContainer>
  );
}
