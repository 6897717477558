import React, { useState } from 'react';
import CustomerTicketsListItem from './CustomerTicketsListItem';
import './CustomerTicketsList.scss';
import { motion } from 'framer-motion';
import { Loader } from 'semantic-ui-react';

const container = {
  show: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const CustomerTicketsList = ({ data, onTicketSelected, loading }) => {
  const [selectedTicket, setSelectedTicket] = useState(null);

  const onTicketSelectedLocal = (ticket) => {
    onTicketSelected(ticket);
    setSelectedTicket(ticket.id);
  };

  return !loading ? (
    data.length ? (
      <motion.div className='tickets-list' variants={container} initial='hidden' animate='show'>
        {data.map((ticket) => (
          <CustomerTicketsListItem
            key={ticket.id}
            ticket={ticket}
            onTicketSelected={onTicketSelectedLocal}
            selectedTicket={selectedTicket}
          />
        ))}
        <div className='center-container'>
          <h4>عدد التذاكر ( {data.length} )</h4>
        </div>
      </motion.div>
    ) : (
      <div className='center-container'>
        <h4>لا يوجد تذاكر</h4>
      </div>
    )
  ) : (
    <div className='center-container'>
      <Loader content='يرجى الانتظار ...' size='large' inline active={loading} />
    </div>
  );
};

export default CustomerTicketsList;
