import Axios from 'axios';
import { getTokenHeader } from './setAuthToken';
import jwt from 'jsonwebtoken';
import { loadState } from './sessionStorage';
import { reduxStore } from './../redux/store/store';
import { userAuthActions } from './../redux/actions/actionCreator';

const axiosConfig = {
  // baseURL: `http://192.168.100.203:3000`,
  // baseURL: `https://cloud.jonav.me/api`,
  baseURL:
    process.env.NODE_ENV === 'development'
      ? `https://${window.location.hostname}:5001/api`
      : `${window.location.protocol}//${window.location.hostname}/api`,
};

const axios = Axios.create(axiosConfig);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      const userState = loadState();
      if (userState.token) {
        const token = jwt.decode(userState.token);
        if (token.exp * 1000 < Date.now()) {
          reduxStore.dispatch(userAuthActions.logout());
          window.location.href = '/login';
          return;
        }
      }
    }
    throw error;
  }
);

// Lookups CRUD
export const lookupsApi = {
  getAll: (pagination = '') => axios.get(`/lookups${pagination}`, getTokenHeader()),
  getOne: (id) => axios.get(`/lookups/${id}`, getTokenHeader()),
  getGroup: (id) => axios.get(`/lookups/dropdownList/${id}`, getTokenHeader()),
  post: (data) => axios.post(`/lookups`, data, getTokenHeader()),
  edit: (id, data) => axios.put(`/lookups/${id}`, data, getTokenHeader()),
  delete: (id) => axios.delete(`/lookups/${id}`, getTokenHeader()),
};

// Roles CRUD
export const rolesApi = {
  getAllRoles: (pagination = '') => axios.get(`/roles${pagination}`, getTokenHeader()),
  getOneRole: (id) => axios.get(`/roles/${id}`, getTokenHeader()),
  postRole: (data) => axios.post(`/roles`, data, getTokenHeader()),
  editRole: (id, data) => axios.put(`/roles/${id}`, data, getTokenHeader()),
  deleteRole: (id) => axios.delete(`/roles/${id}`, getTokenHeader()),
  getAllPermissions: () => axios.get(`/roles/permissions`, getTokenHeader()),
  getPermissionsForRole: (id) => axios.get(`/roles/permissions/${id}`, getTokenHeader()),
};

export const authApi = {
  login: (authCredentials) => axios.post('/users/authenticate', authCredentials),
};

// Users CRUD
export const usersApi = {
  getAllUsers: (pagination = '') => axios.get(`/users${pagination}`, getTokenHeader()),
  getAllExceptCustomersDropdown: () => axios.get(`/users/dropdownlist`, getTokenHeader()),
  getOneUser: (id) => axios.get(`/users/${id}`, getTokenHeader()),
  viewOneUser: (id) => axios.get(`/users/view/${id}`, getTokenHeader()),
  postUser: (data) => axios.post(`/users`, data, getTokenHeader()),
  editUser: (id, data) => axios.put(`/users/${id}`, data, getTokenHeader()),
  editPassword: (data) => axios.put(`/users/ChangePassword`, data, getTokenHeader()),
  deleteUser: (id) => axios.delete(`/users/${id}`, getTokenHeader()),
  verifyUser: (id) => axios.put(`/users/verify/${id}`, null, getTokenHeader()),
  getImageById: (id) => axios.put(`/users/image/${id}`, null, getTokenHeader()),
};

// Customers CRUD
export const customersApi = {
  getAllCustomers: (pagination = '') => axios.get(`/customers${pagination}`, getTokenHeader()),
  getOneCustomer: (id) => axios.get(`/customers/${id}`, getTokenHeader()),
  postCustomer: (data) => axios.post(`/customers`, data, getTokenHeader()),
  editCustomer: (id, data) => axios.put(`/customers/${id}`, data, getTokenHeader()),
  deleteCustomer: (id) => axios.delete(`/customers/${id}`, getTokenHeader()),
  getCustomersDropDown: () => axios.get(`/customers/dropdownList`, getTokenHeader()),
  canBeDeleted: (id) => axios.get(`/customers/canDeleteCustomer/${id}`, getTokenHeader()),
};

// CustomerTickets CRUD
export const customerTicketsApi = {
  getAll: (pagination = '') => axios.get(`/CustomerTickets${pagination}`, getTokenHeader()),
  getOne: (id) => axios.get(`/CustomerTickets/${id}`, getTokenHeader()),
  getRelatedSystemTicket: (relatedTicketId) =>
    axios.get(`/CustomerTickets/SystemTicket/${relatedTicketId}`, getTokenHeader()),
  postSystemTicket: (data) => axios.post(`/CustomerTickets/SystemTicket`, data, getTokenHeader()),
  editSystemTicket: (id, data) => axios.put(`/CustomerTickets/SystemTicket/${id}`, data, getTokenHeader()),
  edit: (id, data) => axios.put(`/CustomerTickets/${id}`, data, getTokenHeader()),
  delete: (queryString) => axios.delete(`/CustomerTickets${queryString}`, getTokenHeader()),
  postComment: (commentData) => axios.post(`/CustomerTickets/Comment`, commentData, getTokenHeader()),
  getTicketComments: (id) => axios.get(`/CustomerTickets/${id}/Comments`, getTokenHeader()),
  changeStatus: (id, status) =>
    axios.put(`/CustomerTickets/${id}/Status`, status, {
      headers: {
        ...getTokenHeader().headers,
        'Content-Type': 'application/json',
      },
    }),
};

// TrackingUnits CRUD
export const trackingUnitsApi = {
  getAllTrackingUnits: (pagination = '') => axios.get(`/trackingUnits${pagination}`, getTokenHeader()),
  getOneTrackingUnit: (id) => axios.get(`/trackingUnits/${id}`, getTokenHeader()),
  getViewOneTrackingUnit: (id) => axios.get(`/trackingUnits/viewOne/${id}`, getTokenHeader()),
  getTrackingUnitsDropDown: () => axios.get(`/trackingUnits/dropdownList`, getTokenHeader()),
  postTrackingUnit: (data) => axios.post(`/trackingUnits`, data, getTokenHeader()),
  editTrackingUnit: (id, data) => axios.put(`/trackingUnits/${id}`, data, getTokenHeader()),
  deleteTrackingUnit: (id) => axios.delete(`/trackingUnits/${id}`, getTokenHeader()),
};

// Vehicles CRUD
export const vehiclesApi = {
  getAllVehicles: (pagination = '') => axios.get(`/vehicles${pagination}`, getTokenHeader()),
  getOneVehicle: (id) => axios.get(`/vehicles/${id}`, getTokenHeader()),
  getVehiclesDropDown: () => axios.get(`/vehicles/dropdownList`, getTokenHeader()),
  postVehicle: (data) => axios.post(`/vehicles`, data, getTokenHeader()),
  editVehicle: (id, data) => axios.put(`/vehicles/${id}`, data, getTokenHeader()),
  deleteVehicle: (id) => axios.delete(`/vehicles/${id}`, getTokenHeader()),
};

// Tracking Vehicles Association CRUD
export const trackingVehiclesApi = {
  getTrackingVehicles: (pagination = '') => axios.get(`/trackingVehicles${pagination}`, getTokenHeader()),
  getTrackingVehiclesList: (id) => axios.get(`/trackingVehicles/dropdown/${id}`, getTokenHeader()),
  getLinkedTrackingUnit: (id) => axios.get(`/trackingVehicles/trackingUnit/${id}`, getTokenHeader()),
  getLinkedVehicle: (id) => axios.get(`/trackingVehicles/vehicle/${id}`, getTokenHeader()),
  getUnlinkedTUList: (id) => axios.get(`/trackingVehicles/dropdown/trackingUnits/${id}`, getTokenHeader()),
  getUnlinkedVehiclesList: (id) => axios.get(`/trackingVehicles/dropdown/vehicles/${id}`, getTokenHeader()),
  postTrackingVehicle: (data) => axios.post(`/trackingVehicles`, data, getTokenHeader()),
  deleteTrackingVehicle: (id) => axios.delete(`/trackingVehicles/${id}`, getTokenHeader()),
  checkTUInMTV: (id) => axios.delete(`/trackingVehicles/tuCheckInMTV/${id}`, getTokenHeader()),
  deleteTuFromMTV: (queryString) => axios.delete(`/trackingVehicles/tuFromMtv${queryString}`, getTokenHeader()),
  checkVehicleInMTV: (id) => axios.delete(`/trackingVehicles/vehicleCheckInMTV/${id}`, getTokenHeader()),
  deleteVehicleFromMTV: (queryString) =>
    axios.delete(`/trackingVehicles/vehicleFromMtv${queryString}`, getTokenHeader()),
};

// MaintenanceForms CRUD
export const maintenanceFormApi = {
  getAllForms: (pagination = '') => axios.get(`/maintenanceForms${pagination}`, getTokenHeader()),
  getOneForm: (id) => axios.get(`/maintenanceForms/${id}`, getTokenHeader()),
  postForm: (data) => axios.post(`/maintenanceForms`, data, getTokenHeader()),
  editForm: (id, data) => axios.put(`/maintenanceForms/${id}`, data, getTokenHeader()),
  deleteForm: (id) => axios.delete(`/maintenanceForms/${id}`, getTokenHeader()),
};

// MaintenanceTickets CRUD
export const maintenanceTicketsApi = {
  getOneMaintenanceTicket: (id) => axios.get(`/maintenanceTickets/${id}`, getTokenHeader()),
  closeMaintenanceTicketVehicle: (id) => axios.get(`/maintenanceTickets/closeTicket/${id}`, getTokenHeader()),
  getFormByMaintenanceTicketVehicle: (id) => axios.get(`/maintenanceTickets/form/${id}`, getTokenHeader()),
  postMaintenanceTicket: (data) => axios.post(`/maintenanceTickets/`, data, getTokenHeader()),
  editMaintenanceTicket: (id, data) => axios.put(`/maintenanceTickets/${id}`, data, getTokenHeader()),
};

// Dashboard
export const dashboardApi = {
  allTickets: () => axios.get(`/dashboard/totalTickets`, getTokenHeader()),
  allTypes: () => axios.get(`/dashboard/totalTypes`, getTokenHeader()),
  trackingUnitsDistribution: () => axios.get(`/dashboard/trackingUnitsDistribution`, getTokenHeader()),
  currentTVTickets: () => axios.get(`/dashboard/currentTrackingVehiclesInTicket`, getTokenHeader()),
  mostCommonActions: () => axios.get(`/dashboard/mostCommonActions`, getTokenHeader()),
  mostCommonIssues: () => axios.get(`/dashboard/mostCommonIssues`, getTokenHeader()),
  mostCommonVehicleIssues: () => axios.get(`/dashboard/mostCommonVehicleIssues`, getTokenHeader()),
  mostProblematicTrackingUnits: () => axios.get(`/dashboard/mostProblematicTrackingUnits`, getTokenHeader()),
  mostProblematicVehicles: () => axios.get(`/dashboard/mostProblematicVehicles`, getTokenHeader()),
  mainPowerAlertsCount: () => axios.get(`/dashboard/mainPowerAlertsCount`, getTokenHeader()),
};

// SLA
export const SystemReports = {
  getReports: (pagination = '') => axios.get(`/systemReports${pagination}`, getTokenHeader()),
  getMonthlySystemTicketsReports: (pagination = '') =>
    axios.get(`/systemReports/monthlySystemTicketsReports${pagination}`, getTokenHeader()),
};
