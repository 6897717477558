import React from 'react';
import DataTable from '../Layout/Table/DataTable';
import { SystemReports } from './../../api/api';

export default function MaintenanceReport() {
  const tableHeaders = [
    {
      displayName: 'تاريخ التقرير',
      fieldName: 'date',
      dbFieldName: 'date',
      sortable: true,
      searchable: true,
      viewable: true,
      searchType: 'text',
      searchValues: [],
      dropdownArMap: {},
    },
    {
      displayName: 'الغرامة',
      fieldName: 'fine',
      dbFieldName: 'fine',
      viewable: true,
      sortable: true,
      searchable: false,
      searchType: 'text',
      component: (value) => `${value} دينار`,
    },
    {
      displayName: 'نسبة الاعطال',
      fieldName: 'percentage',
      dbFieldName: 'percentage',
      viewable: true,
      sortable: false,
      searchable: false,
      searchType: 'text',
      component: (value) => `${(value * 100).toFixed(3)}%`,
    },
    {
      displayName: 'تاريخ إنشاء التقرير',
      fieldName: 'createdDate',
      dbFieldName: 'created_date',
      viewable: true,
      sortable: true,
      searchable: false,
      searchType: 'date',
    },
    {
      displayName: 'عدد التذاكر المفتوحة',
      fieldName: 'openedTickets',
      dbFieldName: 'opened_tickets',
      viewable: true,
      sortable: false,
      searchable: false,
      searchType: 'text',
      component: (value) => value,
    },
    {
      displayName: 'عدد التذاكر الكلي',
      fieldName: 'totalTickets',
      dbFieldName: 'total_tickets',
      viewable: true,
      sortable: false,
      searchable: false,
      searchType: 'text',
      component: (value) => value,
    },
    {
      displayName: 'نوع التقرير',
      fieldName: 'reportTypeName',
      dbFieldName: 'report_type_name',
      viewable: true,
      sortable: false,
      searchable: true,
      searchType: 'dropdown',
      searchValues: [
        { key: 0, text: 'سنوي', value: 'سنوي' },
        { key: 1, text: 'شهري', value: 'شهري' },
      ],
      dropdownArMap: {
        سنوي: 'سنوي',
        شهري: 'شهري',
      },
    },
  ];

  return (
    <>
      <DataTable
        tableTitle='SLA REPORTS'
        headers={tableHeaders}
        actions={[]}
        apiFunction={SystemReports.getReports}
        reloadTrigger={[]}
        optionalQsParams={{}}
      />
    </>
  );
}
