import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Message, Button, Image } from 'semantic-ui-react';
import './UserAuth.scss';
import logo from './../../assets/images/logo.png';
import { authApi } from '../../api/api';
import { saveState } from '../../api/sessionStorage';
import { useDispatch } from 'react-redux';
import { userAuthActions } from './../../redux/actions/actionCreator';
import { loadState } from './../../api/sessionStorage';

export default function Login() {
  const router = useHistory();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    UserName: '',
    Password: '',
  });

  const [validationErrors, setValidationErrors] = useState({
    UserName: null,
    Password: null,
  });

  const [touched, setTouched] = useState({
    UserName: false,
    Password: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [error, setError] = useState('');

  const validation = () => {
    const errors = {
      UserName: null,
      Password: null,
    };

    //UserName VALIDATION
    if (!formData.UserName) {
      errors.UserName = 'الرجاء ادخال اسم المستخدم';
    }

    //Password VALIDATION
    if (!formData.Password) {
      errors.Password = 'الرجاء ادخال كلمة السر';
    }

    setValidationErrors(errors);

    return !validationErrors.UserName && !validationErrors.Password;
  };

  useEffect(() => {
    validation();
  }, [formData]);

  useEffect(() => {
    saveState(null);
  }, []);

  const onChangeHandler = (e) => {
    setTouched({
      ...touched,
      [e.target.name]: true,
    });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    setTouched({
      UserName: true,
      Password: true,
    });

    if (validation()) {
      try {
        const creds = await authApi.login(formData);
        if (creds.status === 200) {
          if (creds.data.token === 'unverified') {
            setError('لم يتم تفعيل الحساب بعد');
            setIsSubmitting(false);
          } else if (creds.data.token === 'notlinked') {
            setError('لم يتم ربط الحساب بعملاء');
            setIsSubmitting(false);
          } else {
            dispatch(userAuthActions.login(creds.data));
            return router.push('/home');
          }
        } else {
          setError('معلومات الدخول غير صحيحة');
          setIsSubmitting(false);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('معلومات الدخول غير صحيحة');
        } else {
          setError('حدث خطأ يرجى المحاولة مجدداً');
        }
        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  return (
    <div className='login-bg'>
      <div className='login-card'>
        <div className='login-header'>
          <Image src={logo} avatar className='logo' />
          <p style={{ fontWeight: 'bold' }}>الشركة العامة للحاسبات والالكترونيات</p>
        </div>
        <Form fluid='true' error autoComplete='off' style={{ margin: '1rem' }} onSubmit={onSubmitHandler}>
          <Form.Field>
            <label>اسم المستخدم</label>
            <Form.Input
              icon='user'
              value={formData['UserName']}
              name='UserName'
              onChange={onChangeHandler}
              error={touched.UserName && validationErrors.UserName}
            />
          </Form.Field>

          <Form.Field>
            <label>كلمة السر</label>
            <Form.Input
              icon='lock'
              type='Password'
              value={formData['Password']}
              name='Password'
              onChange={onChangeHandler}
              error={touched.Password && validationErrors.Password}
            />
          </Form.Field>

          {error && <Message error content={error} />}
          <Button
            fluid
            type='submit'
            className='blue-button'
            loading={isSubmitting}
            disabled={isSubmitting}
            style={{ margin: '1rem 0' }}
          >
            تسجيل الدخول
          </Button>
          <Button
            fluid
            type='button'
            className='button-style'
            onClick={() => router.push('/signup')}
            style={{ margin: '1rem 0' }}
          >
            انشاء حساب جديد
          </Button>
        </Form>
      </div>
    </div>
  );
}
