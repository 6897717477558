import { motion } from 'framer-motion';
import moment from 'moment';
import React from 'react';
import { Icon, Segment } from 'semantic-ui-react';

// TODO: Find a way to keep these lookups consistant across deployments
const statusColor = {
  18: { color: 'orange', icon: 'clock outline' }, // Open
  19: { color: 'blue', icon: 'sync' }, // In Progress
  20: { color: 'green', icon: 'check circle outline' }, // Closed
};

const levelColor = {
  91: { color: 'red' }, // very high
  75: { color: 'orange' }, // High
  74: { color: 'yellow' }, // Intermediate
  73: { color: 'green' }, // low
};

const listItem = {
  hidden: {
    x: 300,
  },
  show: {
    x: 0,
    transition: {
      type: 'easeInOut',
      duration: 0.1,
    },
  },
};

const CustomerTicketsListItem = ({ ticket, onTicketSelected, selectedTicket }) => {
  return (
    <motion.div key={ticket.id} className='ticket-card' variants={listItem}>
      <Segment
        color={levelColor[ticket.levelID].color}
        onClick={() => onTicketSelected(ticket)}
        raised={selectedTicket === ticket.id}
        secondary={selectedTicket === ticket.id}
      >
        <div className='cutomer-ticket-title'>
          <h5 className='customer'>{ticket.fullName}</h5>
          <h5 className='ticket-id'>{ticket.id}#</h5>
        </div>
        <h5 className='title'>{ticket.typeName}</h5>

        <div className='footer'>
          <span>
            <p className={`status-${statusColor[ticket.status].color}`}>{ticket.statusName}</p>
            <Icon size='small' color={statusColor[ticket.status].color} name={statusColor[ticket.status].icon} />
          </span>
          <span>
            <label>{moment(ticket.createdAt).format('YYYY/MM/DD, hh:mm A')}</label>
            <Icon name='clock outline' size='small' />
          </span>
        </div>
        <div className='footer'>
          <span>
            <p>{ticket.levelName}</p>
            <Icon name='exclamation triangle' size='small' />
          </span>
          {ticket.status == 18 && (
            <span>
              <label style={{ color: 'red' }}>
                {moment.utc(moment().diff(moment(ticket.createdAt))).format('D,HH:mm:ss')}
              </label>
            </span>
          )}
        </div>
      </Segment>
    </motion.div>
  );
};

export default CustomerTicketsListItem;
