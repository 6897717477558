import React, { useEffect, useState } from 'react';
import { Button, Form, Dropdown, Accordion, Icon, Popup, Input } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { lookupsApi, customersApi, usersApi } from '../../../api/api';

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);

// TODO: Find a way to keep these lookups consistant across deployments
const statusColor = {
  '-1': { color: 'grey', icon: 'list' }, // all
  18: { color: 'grey', icon: 'clock outline' }, // Open
  19: { color: 'grey', icon: 'sync' }, // In Progress
  20: { color: 'grey', icon: 'check circle outline' }, // Closed
};

const CustomerTicketsSearch = ({ onSearch, loading = false }) => {
  const [filters, setFilters] = useState({
    createdFrom: null,
    createdTo: null,
    type: -1,
    status: 18,
    customerId: -1,
    createdBy: -1,
    closedBy: -1,
    id: '',
  });

  const [filtersVisible, setFiltersVisible] = useState(false);
  const [statusOptions, setStatusOptions] = useState([{ value: -1, key: 0, text: 'الكل' }]);
  const [typeOptions, setTypeOptions] = useState([{ value: -1, key: 0, text: 'الكل' }]);
  const [customersOptions, setCustomersOptions] = useState([{ value: -1, key: 0, text: 'الكل' }]);
  const [usersOptions, setUsersOptions] = useState([{ value: -1, key: 0, text: 'الكل' }]);
  const [clientUsersOptions, setClientUsersOptions] = useState([{ value: -1, key: 0, text: 'الكل' }]);

  useEffect(() => {
    const fillLookups = async () => {
      const type = await lookupsApi.getGroup(14);
      setTypeOptions([...typeOptions, ...type.data.map((item) => ({ value: item.id, text: item.name, key: item.id }))]);

      const status = await lookupsApi.getGroup(17);
      setStatusOptions([
        ...status.data.map((item) => ({ value: item.id, text: item.name, key: item.id })),
        ...statusOptions,
      ]);

      const customers = await customersApi.getCustomersDropDown();
      setCustomersOptions([
        ...customersOptions,
        ...customers.data.map((item) => ({ value: item.id, text: item.name, key: item.id })),
      ]);

      const users = await usersApi.getAllExceptCustomersDropdown();
      setUsersOptions([
        ...usersOptions,
        ...users.data.map((item) => ({ value: item.id, text: item.name, key: item.id })),
      ]);

      // const clientUsers = await usersApi.getUsersDropDown();
      // setClientUsersOptions([
      //  ...clientUsersOptions,
      //  ...clientUsers.data.map((item) => ({ value: item.id, text: item.name, key: item.id }))]);
    };

    fillLookups();
  }, []);

  const onSearchSubmit = (e) => {
    e.preventDefault();
    setFiltersVisible(false);
    const correctedFilters = Object.keys(filters).reduce(
      (a, c) => {
        if (a[c] === -1 || !a[c]) a[c] = null;
        return a;
      },
      { ...filters }
    );
    onSearch(correctedFilters);
  };

  useEffect(() => {
    onSearchSubmit({ preventDefault: () => {} });
  }, [filters.status, filters.createdFrom, filters.createdTo]);

  const onFilterChanged = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const onDateFilterChanged = (key, value) => {
    if (key === 'createdFrom' && (!filters.createdTo || new Date(filters.createdTo) < new Date(value))) {
      const next = new Date(value);
      next.setDate(next.getDate() + 1);
      setFilters({ ...filters, createdFrom: value, createdTo: next });
    } else if (key === 'createdTo' && !filters.createdFrom) {
      const prev = new Date(value);
      prev.setDate(prev.getDate() - 1);
      setFilters({ ...filters, createdTo: value, createdFrom: prev });
    } else {
      setFilters({ ...filters, [key]: value || null });
    }
  };

  return (
    <div className='customer-tickets-filter-container'>
      <Form className='search-box'>
        <div>
          <label className='input-label'>من تاريخ</label>
          <DatePicker
            popperPlacement='top-end'
            placeholderText='من تاريخ'
            selected={filters.createdFrom}
            onChange={(date) => onDateFilterChanged('createdFrom', date)}
            maxDate={today}
            selectsStart
            startDate={filters.createdFrom}
            endDate={filters.createdTo}
          />
        </div>
        <div>
          <label className='input-label'>إلى تاريخ</label>
          <DatePicker
            popperPlacement='top-start'
            placeholderText='إلى تاريخ'
            selected={filters.createdTo}
            onChange={(date) => onDateFilterChanged('createdTo', date)}
            maxDate={tomorrow}
            minDate={filters.createdFrom}
            selectsEnd
            startDate={filters.createdFrom}
            endDate={filters.createdTo}
          />
        </div>
      </Form>
      <label className='input-label'>حالة التذكرة</label>
      <Button.Group fluid className='status-btns-group'>
        {statusOptions.map((status) => (
          <Popup
            trigger={
              <Button
                toggle
                active={status.value === filters.status}
                onClick={() => onFilterChanged('status', status.value)}
                icon={statusColor[status.value].icon}
                color={statusColor[status.value].color}
              />
            }
            key={status.key}
            content={status.text}
          />
        ))}
      </Button.Group>
      <div className='filters'>
        <Accordion styled fluid>
          <Accordion.Title active={filtersVisible} index={0} onClick={() => setFiltersVisible(!filtersVisible)}>
            <Icon name='dropdown' />
            بحث متقدم
          </Accordion.Title>
          <Accordion.Content active={filtersVisible}>
            <Form onSubmit={onSearchSubmit}>
              <label className='input-label'>رقم التذكرة</label>
              <Input
                type='number'
                min={1}
                fluid
                icon='hashtag'
                onChange={(e, v) => onFilterChanged('id', v.value)}
                value={filters.id}
              />
              <label className='input-label'>نوع التذكرة</label>
              <Dropdown
                fluid
                selection
                search
                search
                options={typeOptions}
                onChange={(e, v) => onFilterChanged('type', v.value)}
                value={filters.type}
              />
              <label className='input-label'>العميل</label>
              <Dropdown
                fluid
                selection
                search
                options={customersOptions}
                onChange={(e, v) => onFilterChanged('customerId', v.value)}
                value={filters.customerId}
              />
              <label className='input-label'>أنشأت بواسطة</label>
              <Dropdown
                fluid
                selection
                search
                options={clientUsersOptions}
                onChange={(e, v) => onFilterChanged('createdBy', v.value)}
                value={filters.createdBy}
              />
              <label className='input-label'>أغلقت بواسطة</label>
              <Dropdown
                fluid
                selection
                search
                options={usersOptions}
                onChange={(e, v) => onFilterChanged('closedBy', v.value)}
                value={filters.closedBy}
              />
              <Button
                type='submit'
                fluid
                content='تصفية'
                icon='filter'
                labelPosition='left'
                className='filter-btn'
                loading={loading}
                disabled={loading}
              />
            </Form>
          </Accordion.Content>
        </Accordion>
      </div>
    </div>
  );
};

export default CustomerTicketsSearch;
