import { userAuth, tickets } from './index';

export const userAuthActions = {
  //saga listens to LOGIN_START
  createLoginRequest: (payload) => ({
    type: userAuth.LOGIN_START,
    payload: payload,
  }),
  //dispatches if saga was successful
  login: (payload) => ({
    type: userAuth.LOGIN_SUCCESS,
    payload: payload,
  }),
  logout: () => ({
    type: userAuth.LOGOUT,
  }),
  //dispatched if saga failed
  loginFailed: (err) => ({
    type: userAuth.LOGIN_FAILED,
    payload: err,
  }),
  //update data in redux store
  updateData: (payload) => ({
    type: userAuth.UPDATE_DATA,
    payload: payload,
  }),
};

export const ticketsActions = {
  setSelectedTicket: (ticket) => ({
    type: tickets.SET_SELECTED_TICKET,
    payload: ticket,
  }),
  clearSelectedTicket: () => ({
    type: tickets.CLEAR_SELECTED_TICKET,
  }),
};
