import moment from 'moment';
import React from 'react';
import { Icon } from 'semantic-ui-react';

const SysTicketInfoPanel = ({ ticket }) => {
  const DataBox = ({ title, icon = null, data, ltr = false }) => {
    return (
      <div className='data-box'>
        <div className='title-container'>
          {icon && <Icon name={icon} size='small' />}
          <h5 className='title'>{title}</h5>
        </div>
        <h5 className={`data ${ltr ? 'ltr' : ''}`}>{data}</h5>
      </div>
    );
  };

  return (
    <>
      <DataBox title='العميل' icon='building' data={ticket.fullName} />
      <DataBox title='نوع التذكره' icon='ticket' data={ticket.typeName} />
      <DataBox title='منشئ التذكرة' icon='user circle' data={ticket.createdBy} />
      <DataBox title='بتاريخ' ltr icon='calendar' data={moment(ticket.createdAt).format('YYYY/MM/DD, hh:mm A')} />
      {ticket.closedBy && (
        <>
          <DataBox
            title='مدة الاستجابة'
            icon='user circle'
            data={moment.utc(moment(ticket.responseAt).diff(moment(ticket.createdAt))).format('D,HH:mm:ss')}
          />
          <DataBox
            title='مدة التنفيذ'
            icon='user circle'
            data={moment.utc(moment(ticket.closedAt).diff(moment(ticket.responseAt))).format('D,HH:mm:ss')}
          />
          <DataBox title='مغلق التذكرة' icon='user circle' data={ticket.closedBy} />
          <DataBox
            title='بتاريخ'
            ltr
            icon='calendar check'
            data={moment(ticket.closedAt).format('YYYY/MM/DD, hh:mm A')}
          />
        </>
      )}
    </>
  );
};

export default React.memo(SysTicketInfoPanel);
