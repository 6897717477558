export const userAuth = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGOUT: 'LOGOUT',
  LOGIN_START: 'LOGIN_START',
  LOGIN_FAILED: 'LOGIN_FAILED',
  UPDATE_DATA: 'UPDATE_DATA',
};

export const tickets = {
  SET_SELECTED_TICKET: 'SET_SELECTED_TICKET',
  CLEAR_SELECTED_TICKET: 'CLEAR_SELECTED_TICKET',
};
