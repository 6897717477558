import React from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import CustomerTicketsSearch from '../CustomerTickets/CustomerTicketsSearch';
import CustomerTicketsList from '../CustomerTickets/CustomerTicketsList';

const TicketsSideMenu = ({ data, fetchData, loading, onTicketSelected, exportFileHandler }) => {
  return (
    <div className='tickets-menu'>
      <div>
        <div className='top-container'>
          <h2 className='main-title'>تذاكر الصيانة</h2>
          <Popup
            content='تصدير التذاكر الى ملف اكسل'
            trigger={
              <Button icon className='excel-button' basic onClick={() => exportFileHandler()}>
                <Icon name='file excel' />
              </Button>
            }
          />
        </div>
        <hr className='divider' />
        <CustomerTicketsSearch
          onSearch={(filters) => {
            fetchData(filters);
          }}
          loading={loading}
        />
        <CustomerTicketsList data={data} onTicketSelected={onTicketSelected} loading={loading} />
      </div>
    </div>
  );
};

export default TicketsSideMenu;
