import React from 'react';
import DataTable from '../Layout/Table/DataTable';
import { SystemReports } from './../../api/api';

export default function MonthlySystemTicketsReport() {
  const tableHeaders = [
    {
      displayName: 'تاريخ التقرير',
      fieldName: 'reportMonth',
      dbFieldName: 'report_month',
      sortable: true,
      searchable: true,
      viewable: true,
      searchType: 'text',
      searchValues: [],
      dropdownArMap: {},
    },
    {
      displayName: 'نسبة الاعطال',
      fieldName: 'percentage',
      dbFieldName: 'percentage',
      viewable: true,
      sortable: false,
      searchable: false,
      searchType: 'text',
      component: (value) => `${(100 - value).toFixed(3)}%`,
    },
    {
      displayName: 'الغرامة',
      fieldName: 'fines',
      dbFieldName: 'fines',
      viewable: true,
      sortable: true,
      searchable: false,
      searchType: 'text',
      component: (value) => `${value} دينار`,
    },
    {
      displayName: 'تاريخ إنشاء التقرير',
      fieldName: 'createdAt',
      dbFieldName: 'created_at',
      viewable: true,
      sortable: true,
      searchable: false,
      searchType: 'date',
    },
  ];

  return (
    <>
      <DataTable
        tableTitle='SLA REPORTS'
        headers={tableHeaders}
        actions={[]}
        apiFunction={SystemReports.getMonthlySystemTicketsReports}
        reloadTrigger={[]}
        optionalQsParams={{}}
      />
    </>
  );
}
