import React from 'react';
import { motion } from 'framer-motion';
import { Card } from 'semantic-ui-react';
import SettingsCard from '../Settings/SettingsList/SettingsCard';
import PermissionCheck from '../Reusable/PermissionCheck';
import p, { hasPermission, hasPermissions } from '../../helpers/permissions';

const TicketsMenu = () => {
  return (
    <div className='settings-list'>
      <Card.Group className='settings-card-group max-width-800'>
        <PermissionCheck
          permissions={[
            p.VIEW_MAINTENANCE_TICKETS,
            p.VIEW_ALL_MAINTENANCE_TICKETS,
            p.CREATE_SYSTEM_TICKET,
            p.CREATE_TRACKING_VEHICLE_TICKET,
          ]}
        >
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard title='عرض جميع التذاكر' iconName='ticket' url='/sys-tickets' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck
          permissions={[
            p.CREATE_REPORT_WITHOUT_TICKET,
            p.EDIT_MAINTENANCE_REPORTS,
            p.VIEW_MAINTENANCE_REPORTS,
            p.VIEW_ALL_MAINTENANCE_REPORTS,
            p.DELETE_MAINTENANCE_REPORT,
            p.CREATE_TRACKING_VEHICLE_TICKET,
          ]}
        >
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: -100 }}
          >
            <SettingsCard title='عرض تقارير صيانة أجهزة التتبع' iconName='hdd' url='/maintenanceForm' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck permissions={p.CREATE_TRACKING_VEHICLE_TICKET}>
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard title='إضافة تذكرة صيانة أجهزة تتبع' iconName='plus square' url='/maintenanceTicket/add' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck permissions={p.CREATE_SYSTEM_TICKET}>
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: -100 }}
          >
            <SettingsCard title='إضافة تذكرة صيانة نظام' iconName='plus square' url='/sys-tickets/add' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck permissions={[p.VIEW_SLA]}>
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard title='اتفاقية مستوى الخدمة لأجهزة التتبع' iconName='dollar' url='/sys-reports' />
          </motion.div>
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard
              title='اتفاقية مستوى الخدمة لصيانة النظام'
              iconName='dollar'
              url='/monthly-sys-tickets-reports'
            />
          </motion.div>
        </PermissionCheck>
      </Card.Group>
    </div>
  );
};

export default TicketsMenu;
