import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Button, Icon, Checkbox, Message, Popup, Responsive } from 'semantic-ui-react';
import { rolesApi } from '../../../api/api';
import PageCardContainer from '../../Layout/PageCardContainer';
import _ from 'lodash';
import { hasPermission } from '../../../helpers/permissions';

export default function EditRoleForm() {
  const router = useHistory();
  const location = useLocation();
  const { id } = location.state;

  const [allPermissions, setAllPermissions] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    permissions: [],
  });

  const [validationErrors, setValidationErrors] = useState({
    name: null,
  });

  const [touched, setTouched] = useState({
    name: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // get the editable role
        const oneRole = await rolesApi.getOneRole(id);
        setFormData(oneRole.data);

        // get the permissions of the role
        const rolePermissions = await rolesApi.getPermissionsForRole(id);

        // get all permissions
        const permissions = await rolesApi.getAllPermissions();
        let results = permissions.data;
        //add isChecked property
        for (let result of results) {
          const found = rolePermissions.data.some((el) => el.id === result.id);
          if (found) result.isChecked = true;
          else result.isChecked = false;
        }
        setAllPermissions(results);
      } catch (err) {
        console.log('Error fetching data...', err);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    validation();
  }, [formData]);

  const validation = () => {
    const errors = {
      name: null,
    };

    //NAME VALIDATION
    if (!formData.name) {
      errors.name = 'الرجاء ادخال اسم مجموعة الصلاحيات';
    }

    setValidationErrors(errors);

    if (validationErrors.name) return false;
    else return true;
  };

  const onChangeHandler = (e) => {
    setTouched({
      ...touched,
      [e.target.name]: true,
    });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const checkboxChangeHandler = (id) => {
    //if id exists in permissions list, remove it
    //otherwise add it
    if (!hasPermission(id)) {
      return;
    }
    let newPermissionsState = [...allPermissions];
    let permission = newPermissionsState.find((perm) => perm.id === id);
    permission.isChecked = !permission.isChecked;
    setAllPermissions(newPermissionsState);
  };

  const checkPermsByGroup = (groupName, evt) => {
    let newPermissionsState = [...allPermissions];
    let permissionIds = newPermissionsState.filter((perm) => perm.groupName === groupName);
    for (let i = 0; i < permissionIds.length; i++) {
      const permission = permissionIds[i];
      if (hasPermission(permission.id)) {
        permission.isChecked = evt.checked;
      }
    }
    setAllPermissions(newPermissionsState);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setTouched({
      name: true,
    });

    // construct permissions list of ids
    let formPermissions = [];
    for (let p of allPermissions) {
      if (p.isChecked) formPermissions.push(p.id);
    }

    let errorsVar = {};

    // PERMISSIONS VALIDATION
    if (!formPermissions.length) {
      errorsVar = { key: 0, message: 'الرجاء اختيار صلاحيات للمجموعة' };
      setErrors([errorsVar]);
    }

    if (_.isEmpty(errorsVar) && validation()) {
      try {
        await rolesApi.editRole(id, {
          ...formData,
          permissions: formPermissions,
        });
        router.goBack();
      } catch (e) {
        console.log('Error editing role...', e);
        setIsSubmitting(false);
        setErrors([{ key: 1, message: 'حدث خطأ اثناء الحفظ' }]);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  return (
    <PageCardContainer>
      <div style={{ margin: '1rem' }} className='form-margin'>
        <h2>تعديل مجموعة الصلاحيات</h2>
        <div style={{ marginTop: '3rem' }}>
          <Form error onSubmit={onSubmitHandler}>
            <Form.Field className='field-wrapper' style={{ marginLeft: '1rem' }}>
              <label>اسم مجموعة الصلاحيات</label>
              <Form.Input
                icon='star'
                value={formData['name']}
                name='name'
                onChange={onChangeHandler}
                error={touched.name && validationErrors.name}
              />
            </Form.Field>

            <label>صلاحيات المجموعة</label>

            <div
              style={{
                margin: '1rem',
              }}
            >
              {Object.keys(_.groupBy(allPermissions, 'groupName')).map((key) => {
                return (
                  <div key={key}>
                    <h5 style={{ marginTop: 20 }}>
                      <Checkbox onChange={(e, v) => checkPermsByGroup(key, v)} /> ✩ {key}
                    </h5>
                    {_.groupBy(allPermissions, 'groupName')[key].map((permission, i) => {
                      return (
                        <div key={permission.id} style={{ margin: '0.5rem 1rem' }}>
                          {!hasPermission(permission.id) ? (
                            <Popup
                              content='لا يمكن اعطاء او ازالة صلاحيات لا تملكها'
                              position='top right'
                              trigger={
                                <Checkbox
                                  label={permission.name}
                                  name={permission.name}
                                  checked={permission.isChecked}
                                />
                              }
                            />
                          ) : (
                            <Checkbox
                              label={permission.name}
                              name={permission.name}
                              checked={permission.isChecked}
                              onClick={() => checkboxChangeHandler(permission.id)}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>

            {errors.map((err) => (
              <Message error key={err.key} content={err.message} />
            ))}

            <Responsive minWidth={768}>
              <div style={{ marginTop: '2rem' }}>
                <Button
                  icon
                  type='submit'
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  className='blue-button'
                  labelPosition='right'
                  style={{ marginLeft: '1rem' }}
                >
                  <Icon name='save' className='blue-buton-icon' />
                  حفظ
                </Button>

                <Button
                  icon='chevron right'
                  labelPosition='right'
                  content='رجوع'
                  onClick={(e) => {
                    e.preventDefault();
                    router.goBack();
                  }}
                />
              </div>
            </Responsive>
            <Responsive maxWidth={767}>
              <div style={{ marginTop: '2rem' }}>
                <Button
                  icon
                  fluid
                  type='submit'
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  className='blue-button'
                  labelPosition='right'
                  style={{ marginBottom: '1rem' }}
                >
                  <Icon name='save' className='blue-buton-icon' />
                  حفظ
                </Button>

                <Button
                  fluid
                  icon='chevron right'
                  labelPosition='right'
                  content='رجوع'
                  onClick={(e) => {
                    e.preventDefault();
                    router.goBack();
                  }}
                />
              </div>
            </Responsive>
          </Form>
        </div>
      </div>
    </PageCardContainer>
  );
}
