import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers/rootReducer';

const loggerMiddleware = createLogger();

let reduxStore;

const middleWares = [
  /*loggerMiddleware*/
];

const store = () => {
  const storeConfig = createStore(rootReducer, applyMiddleware(...middleWares));

  reduxStore = storeConfig;

  return storeConfig;
};

export { reduxStore };

export default store();
