import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Transition, Modal } from 'semantic-ui-react';
import PageCardContainer from '../../Layout/PageCardContainer';
import DataTable from '../../Layout/Table/DataTable';
import { customersApi, lookupsApi, rolesApi, usersApi } from './../../../api/api';
import PermissionCheck from '../../Reusable/PermissionCheck';
import permissions, { hasPermission } from '../../../helpers/permissions';

export default function Users() {
  const router = useHistory();
  const [jobTitleOptions, setJobTitleOptions] = useState([]);
  const [jobTitlesArMap, setJobTitlesArMap] = useState({});
  const [rolesOptions, setRolesOptions] = useState([]);
  const [rolesArMap, setRolesArMap] = useState({});
  const [customerIdOptions, setCustomerIdOptions] = useState([]);
  const [customerIdArMap, setCustomerIdArMap] = useState({});
  const [verifyModal, setVerifyModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      //get lookups for jobtitles
      const jobTitles = await lookupsApi.getGroup(2);
      //loop over the data and store in an array of objects for select options
      let options1 = [];
      let options1ArMap = {};
      for (let item of jobTitles.data) {
        options1.push({ key: item.id, text: item.name, value: item.id });
        options1ArMap[item.id] = item.name;
      }
      setJobTitleOptions(options1);
      setJobTitlesArMap(options1ArMap);

      //get roles
      const roles = await rolesApi.getAllRoles();
      let options2 = [];
      let options2ArMap = {};
      for (let item of roles.data.data) {
        options2.push({ key: item.id, text: item.name, value: item.id });
        options2ArMap[item.id] = item.name;
      }
      setRolesOptions(options2);
      setRolesArMap(options2ArMap);

      //get customers
      const customers = await customersApi.getCustomersDropDown();
      let options3 = [];
      let options3ArMap = {};
      options3ArMap['-1'] = 'موظف / ليس عميل';
      for (let item of customers.data) {
        options3.push({ key: item.id, text: item.name, value: item.id });
        options3ArMap[item.id] = item.name;
      }
      options3.unshift({ key: -1, text: 'موظف / ليس عميل', value: -1 });
      setCustomerIdOptions(options3);
      setCustomerIdArMap(options3ArMap);
    };

    fetchData();
  }, []);

  const tableHeaders = [
    {
      // a field that is used for display only (in arabic)
      displayName: 'اسم المستخدم',
      // a field that is used as a key (in english camelCase)
      fieldName: 'userName',
      // for sort, needs to be snake_case to match db field
      dbFieldName: 'user_name',
      sortable: true,
      searchable: true,
      // whether it should be viewed in the table or not
      viewable: true,
      // either text, dropdown, or date
      searchType: 'text',
      // key, text, value options for dropdown searchType
      searchValues: [],
      // the dropdown options english: arabic object (used for labeling search items)
      dropdownArMap: {},
    },
    {
      displayName: 'الاسم الكامل',
      fieldName: 'fullName',
      dbFieldName: 'full_name',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'text',
    },
    {
      displayName: 'الهاتف',
      fieldName: 'phone',
      dbFieldName: 'phone',
      viewable: false,
      sortable: true,
      searchable: true,
      searchType: 'text',
    },
    {
      displayName: 'الايميل',
      fieldName: 'email',
      dbFieldName: 'email',
      viewable: false,
      sortable: true,
      searchable: true,
      searchType: 'text',
    },
    {
      displayName: 'المسمى الوظيفي',
      fieldName: 'jobTitle',
      dbFieldName: 'job_title',
      // only show it in the search options
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'dropdown',
      searchValues: jobTitleOptions,
      dropdownArMap: jobTitlesArMap,
    },
    {
      displayName: 'الصلاحيات',
      fieldName: 'role',
      dbFieldName: 'role',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'dropdown',
      searchValues: rolesOptions,
      dropdownArMap: rolesArMap,
    },
  ];

  const handleVerifyUser = async (id) => {
    setVerifyModal(true);
    await usersApi.verifyUser(id);
  };

  const tableActions = [
    ...(hasPermission(permissions.ACTIVATE_NEW_USER)
      ? [
          {
            actionType: 'custom',
            onClick: handleVerifyUser,
            title: 'تفعيل المستخدم',
            icon: 'check',
            reloadOnClick: true,
          },
        ]
      : []),
    {
      actionType: 'view',
      actionUrl: '/users/view',
    },
    ...(hasPermission(permissions.EDIT_USER)
      ? [
          {
            actionType: 'edit',
            actionUrl: '/users/edit',
          },
        ]
      : []),
    ...(hasPermission(permissions.CHANGE_USER_PASSWORD)
      ? [
          {
            actionType: 'editPassword',
            actionUrl: '/users/editPassword',
          },
        ]
      : []),
    ...(hasPermission(permissions.DELETE_USER)
      ? [
          {
            actionType: 'delete',
            deleteActionApi: usersApi.deleteUser,
          },
        ]
      : []),
  ];

  return (
    <div>
      <PageCardContainer>
        <div className='table-title-container'>
          <h3>المستخدمين</h3>
          <PermissionCheck permissions={permissions.CREATE_USER}>
            <Button icon labelPosition='right' className='blue-button' onClick={() => router.push('/users/add')}>
              <Icon name='plus' className='blue-buton-icon' />
              اضافة مستخدم جديد
            </Button>
          </PermissionCheck>
        </div>
        <DataTable
          tableTitle='المستخدمين'
          headers={tableHeaders}
          actions={tableActions}
          apiFunction={usersApi.getAllUsers}
          optionalQsParams={{}}
          reloadTrigger={[]}
        />
      </PageCardContainer>

      <Transition visible={verifyModal} animation='scale' duration={1000}>
        <Modal size='small' open={verifyModal}>
          <Modal.Header>تمت العملية بنجاح</Modal.Header>
          <Modal.Content>
            <div className='delete-ticket-errors-modal'>
              <p>لقد تم تفعيل الحساب بنجاح.</p>
              <p className='red-text'>
                يجب اضافة عميل واحد على الاقل لهذا الحساب لكي يتمكن المستخدم من الدخول الى النظام.
              </p>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button color='blue' onClick={() => setVerifyModal(false)}>
              موافق
            </Button>
          </Modal.Actions>
        </Modal>
      </Transition>
    </div>
  );
}
